import { CSSProperties, useState } from 'react';
import { Box, BoxProps, Button, Center, Fade } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';
import { VideoJsPlayer } from 'video.js';

import { MediaTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { PlayableMedia } from '@arena-labs/shared-models';
import { toast, useVideoPlayerInTimeRange } from '@arena-labs/strive2-ui';

export type EndPromptProps = {
  media: PlayableMedia;
  nextMedia?: PlayableMedia;
  onReplay?: () => unknown;
  onContinue: () => unknown;
  continuePending?: boolean;
  isActive?: boolean;
};

export type EndPromptDisplayProps = {
  player?: VideoJsPlayer;
  show?: boolean;
  beforeContinue?: () => unknown;
  onClose?: () => unknown;
  media: PlayableMedia;
  nextMedia?: PlayableMedia;
  upNext?: string;
  box?: BoxProps;
  position?: CSSProperties['position'];
  isActive?: boolean;
  render?: (props: EndPromptProps) => JSX.Element;
};
export function EndPromptDisplay({
  player,
  show,
  render = (props) => <DefaultMediaQueueEndPrompt {...props} />,
  ...props
}: EndPromptDisplayProps) {
  const analytics = useAnalytics();
  const swiper = useSwiper();
  const showButton = useVideoPlayerInTimeRange({
    player,
    start: -1.5,
  });
  const [continuePending, setContinuePending] = useState(false);
  const onContinue = async () => {
    analytics.logEvent(MediaTrackingEvent.MediaQueueContinueClicked);
    setContinuePending(true);
    try {
      await props.beforeContinue?.();
      if (swiper.isEnd) {
        props.onClose?.();
        return;
      } else {
        swiper.slideNext();
      }
    } catch (e) {
      console.error(e);
      toast({ status: 'error', title: 'An error occurred' });
    } finally {
      setContinuePending(false);
    }
  };

  const onReplay = player
    ? () => {
        player.currentTime(0);
        player.play();
        analytics.logEvent(MediaTrackingEvent.MediaQueueReplayClicked);
      }
    : undefined;

  return (
    <Fade
      in={show || showButton}
      unmountOnExit
      style={{ position: props.position ?? 'initial', zIndex: 10 }}
    >
      <Box
        position="absolute"
        inset="auto 0 0"
        p="4"
        bg={'neutral.white'}
        borderRadius="card"
        {...props.box}
      >
        {render({
          media: props.media,
          nextMedia: props.nextMedia,
          onContinue: onContinue,
          onReplay: onReplay,
          continuePending: continuePending,
          isActive: props.isActive,
        })}
      </Box>
    </Fade>
  );
}

export function DefaultMediaQueueEndPrompt(
  props: EndPromptProps & { buttonText?: string },
) {
  return (
    <Center>
      <Button
        variant="primary"
        isLoading={props.continuePending}
        onClick={props.onContinue}
      >
        {props.buttonText ?? 'CONTINUE'}
      </Button>
    </Center>
  );
}
