import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  Icon,
  Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useAnimate, usePresence } from 'framer-motion';

import { PillarPractice } from '@arena-labs/shared-models';
import { ChevronLeftIcon, InfoIcon } from '@arena-labs/strive2-ui';
import { StriveApiResponse } from '@strive/api';

import {
  AdditionalInsightCards,
  InsightsRechargeCard,
} from '../recharge-cards';

export function DailyInsights({
  data,
  onOpenContext,
  logPracticeCard,
}: {
  data: StriveApiResponse<'submitACI2'>;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
  onOpenContext: () => void;
}) {
  const [insightsExpanded, setInsightsExpanded] = useState(false);
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      // no animation
    } else {
      const exitAnimation = async () => {
        await animate(
          '#body',
          { opacity: 0 },
          {
            duration: 0.35,
          },
        );
        await animate(
          scope.current,
          { opacity: 0 },
          {
            duration: 0.35,
          },
        );

        safeToRemove && safeToRemove();
      };
      exitAnimation();
    }
  }, [isPresent, animate, safeToRemove, scope]);

  return (
    <Box ref={scope}>
      <Flex
        h={'full'}
        id={'body'}
        mx={6}
        opacity={1}
        direction={'column'}
        gap={4}
      >
        <Text textStyle={'h3'}>
          {!data?.daily?.latest_hrv ? (
            `No data from last night.`
          ) : (
            <>
              <span>
                Your Inner State &amp; HRV indicate
                {data?.daily?.inner_state_trend === data?.daily?.hrv_trend
                  ? ' similar'
                  : ' opposing'}{' '}
                trends{' '}
              </span>
            </>
          )}
          <Button
            ml={1}
            onClick={() => onOpenContext()}
            variant={'unstyled'}
            h={6}
            p={0}
            color={'neutral.700'}
            size={'xs'}
          >
            <Icon as={InfoIcon} boxSize={6} />
          </Button>{' '}
        </Text>

        <Flex direction={'column'} rounded={'button'} py={2} w={'full'}>
          <Text textStyle={'copy_bold'} mx={'auto'} mb={3}>
            {' '}
            Last 24 Hours
          </Text>

          <Grid templateColumns={'1fr 1fr'} w={'full'} gap={3} mb={3}>
            <InsightsRechargeCard
              selection={data?.daily?.inner_state_trend}
              isDataCard={false}
              expanded={insightsExpanded}
            />
            <InsightsRechargeCard
              selection={data?.daily?.hrv_trend}
              isDataCard={true}
              expanded={insightsExpanded}
              hasHRV={Boolean(data?.daily?.latest_hrv)}
            />
          </Grid>

          <Collapse in={insightsExpanded}>
            <Grid templateColumns={'1fr 1fr'} w={'full'} gap={3}>
              <AdditionalInsightCards
                metric={'Practice'}
                value={`${data.daily?.practice_count} completed`}
                context={`${data.daily?.practice_previous_count} from ${format(
                  new Date(data.daily?.practice_trend_from ?? 0),
                  'MMM dd',
                )}`}
                contextIndicator={true}
                trend={data.daily?.practice_trend ?? null}
              />

              <AdditionalInsightCards
                metric={'HRV'}
                value={
                  data.daily?.latest_hrv ? `${data?.daily?.latest_hrv} ms` : '-'
                }
                context={`8 day avg: ${data?.daily?.baseline_hrv} ms`}
                trend={data.daily?.hrv_trend ?? null}
              />
            </Grid>
          </Collapse>

          <Button
            mt={insightsExpanded ? 3 : 0}
            transition={'all 0.33s ease'}
            variant={'unstyled'}
            w={'full'}
            p={0}
            h={'4'}
            onClick={() => setInsightsExpanded(!insightsExpanded)}
          >
            <Icon
              as={ChevronLeftIcon}
              transform={`rotate(${insightsExpanded ? '90' : '-90'}deg)`}
              transition={'all 0.33s ease'}
              boxSize={3}
              mx={'auto'}
            />
          </Button>
        </Flex>

        <Flex direction={'column'} gap={9} px={2} py={4}>
          <Box>
            <Text textStyle={'h4'} mb={1}>
              {data?.daily?.llm_title}
            </Text>
            <Text textStyle={'copy_small'}>{data?.daily?.llm_description}</Text>
          </Box>
          {data?.daily?.recommended_practice && (
            <Box>
              <Text textStyle={'copy_bold'} mb={4}>
                Try This Practice
              </Text>
              {logPracticeCard(data.daily.recommended_practice)}
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
