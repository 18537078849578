import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneSchema } from '@arena-labs/shared-models';
import { AlertBox, PhoneNumberInput } from '@arena-labs/strive2-ui';
import { useAuthState } from '@strive/api';

export type LoginFormState = yup.Asserts<typeof loginFormSchema>;
export const loginFormSchema = yup.object({
  phone: phoneSchema,
});

export type LoginFormProps = {
  defaultPhone?: string | null;
  onSubmit: (phone: string) => void;
  error?: unknown;
};

export function LoginForm({ defaultPhone, onSubmit, error }: LoginFormProps) {
  const { handleSubmit, formState, control } = useForm<LoginFormState>({
    resolver: yupResolver(loginFormSchema),
    defaultValues: {
      phone: defaultPhone ?? '',
    },
  });

  const [authState] = useAuthState();

  return (
    <form
      onSubmit={handleSubmit(({ phone }) => onSubmit(phone))}
      style={{ height: '100%', width: '100%', marginTop: '40px' }}
    >
      <Flex direction={'column'} gap="5" h="full" align="flex-start">
        <Text
          textStyle={'h2'}
          color={'neutral.800'}
          transition={'all 1s linear'}
        >
          Let&apos;s Get Started
        </Text>
        <FormControl
          id="login-phone"
          isInvalid={Boolean(formState.errors?.phone)}
        >
          <PhoneNumberInput name="phone" control={control} />
          <FormErrorMessage>{formState.errors.phone?.message}</FormErrorMessage>
        </FormControl>

        {!!error && (
          <AlertBox showIcon status="error" title="No account found" />
        )}

        {!authState.matches('Logged in') && (
          <>
            <Button type="submit" variant="primary" w="full" mt={'auto'}>
              Send Code
            </Button>
          </>
        )}
      </Flex>
    </form>
  );
}
