import { useForm, useWatch } from 'react-hook-form';
import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Card, toast } from '@arena-labs/strive2-ui';
import { useStriveEnvironments } from '@strive/api';

import { useEnvironmentMutation } from './admin-mutations';
import { EnvironmentData, environmentSchema } from './admin-schema';

export type AdminEnvironmentsProps = {
  formId: string;
  onSuccess: () => void;
};

export function AdminEnvironments({
  formId,
  ...props
}: AdminEnvironmentsProps) {
  const switchEnvironment = useEnvironmentMutation();
  const { current, environments, customEnvironment } = useStriveEnvironments();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<EnvironmentData>({
    resolver: yupResolver(environmentSchema),
    defaultValues: {
      environment: current,
      server: customEnvironment?.server || '',
      api: customEnvironment?.api || '',
    },
  });

  const environment = useWatch({ control, name: 'environment' });
  const selectedEnv =
    environment === 'custom' ? customEnvironment : environments[environment];

  const onSubmit = async (values: EnvironmentData) => {
    await switchEnvironment.mutateAsync(values);
    props.onSuccess();
    toast({ title: 'Environment switched', status: 'success' });
  };

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      spacing="4"
      align="stretch"
    >
      <FormControl id="env-id" isInvalid={Boolean(errors.environment)}>
        <FormLabel fontWeight="bold" mb="0">
          ENVIRONMENT
        </FormLabel>
        <Select {...register('environment')}>
          {Object.entries(environments)?.map(([envId, env]) => (
            <option key={envId} value={envId}>
              {env.name}
            </option>
          ))}
          <option key="custom" value="custom">
            Custom environment...
          </option>
        </Select>
        <FormErrorMessage>{errors.environment?.message}</FormErrorMessage>
      </FormControl>

      {environment !== 'custom' && selectedEnv && (
        <Card colorScheme="dark">
          <dl>
            <Text as="dt" fontWeight="bold">
              Server
            </Text>
            <Text
              as="dd"
              fontFamily="monospace"
              whiteSpace="nowrap"
              overflowX="auto"
            >
              {selectedEnv.server}
            </Text>
            <Divider my="4" />
            <Text as="dt" fontWeight="bold">
              API
            </Text>
            <Text
              as="dd"
              fontFamily="monospace"
              whiteSpace="nowrap"
              overflowX="auto"
            >
              {selectedEnv.api}
            </Text>
          </dl>
        </Card>
      )}
      {environment === 'custom' && (
        <>
          <FormControl id="env-server" isInvalid={Boolean(errors.server)}>
            <FormLabel fontWeight="bold" mb="0">
              SERVER
            </FormLabel>
            <Input type="text" autoComplete="none" {...register('server')} />
            <FormErrorMessage>{errors.server?.message}</FormErrorMessage>
          </FormControl>

          <FormControl id="env-api" isInvalid={Boolean(errors.api)}>
            <FormLabel fontWeight="bold" mb="0">
              API
            </FormLabel>
            <Input type="text" autoComplete="none" {...register('api')} />
            <FormErrorMessage>{errors.api?.message}</FormErrorMessage>
          </FormControl>
        </>
      )}
    </VStack>
  );
}
