import {
  Box,
  Button,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  Heading,
  Icon,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';

import { CoachDetail } from '@arena-labs/shared-models';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  formatCoachName,
  NavigationDrawer,
  SwipeableDrawerBody,
} from '@arena-labs/strive2-ui';

export interface CoachLinksProps {
  coach: CoachDetail;
  isOpen: boolean;
  onClose: () => void;
}

export function CoachLinks({ coach, isOpen, onClose }: CoachLinksProps) {
  return (
    <NavigationDrawer
      placement="top"
      onClose={onClose}
      size="full"
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader p="0">
          <Grid templateColumns="50px 1fr 50px" alignItems="center">
            <Button
              onClick={onClose}
              backgroundColor="transparent"
              p={4}
              ml={-4}
            >
              <VisuallyHidden>Go back to coach details</VisuallyHidden>
              <Icon as={ChevronLeftIcon} color="strive.secondary" />
            </Button>
          </Grid>
        </DrawerHeader>
        <SwipeableDrawerBody
          as="section"
          p="6"
          direction="Up"
          onSwipe={() => onClose()}
          delta={250}
        >
          <VStack spacing={5} alignItems="flex-start">
            <Heading
              as="h2"
              fontWeight="bold"
              fontSize="2xl"
              justifySelf="center"
            >
              {formatCoachName(coach)} links
            </Heading>
            <Text textStyle={'copy'}>
              Please note that these links will take you away from the Arena app
              and may open a new tab or app on your device.
            </Text>
            <Spacer />
            {coach.coach_links?.map((link) => (
              <LinkBox
                as="article"
                display="flex"
                alignItems="center"
                w="100%"
                key={link.link}
              >
                <Box>
                  <LinkOverlay
                    href={link.link}
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    <Text textStyle={'caption'} textTransform="uppercase">
                      {link.title}
                    </Text>
                  </LinkOverlay>
                  <Text textStyle={'copy_small'} display="block">
                    {link.description}
                  </Text>
                </Box>
                <Icon as={ChevronRightIcon} ml="auto" />
              </LinkBox>
            ))}
          </VStack>
        </SwipeableDrawerBody>
      </DrawerContent>
    </NavigationDrawer>
  );
}
