import { Container, ContainerProps, Grid } from '@chakra-ui/react';

import { useGradient } from '../utils/use-gradient';

export type AppContainerProps = ContainerProps & {
  appLayout?: boolean;
  fixedHeight?: boolean;
};

export function AppContainer({
  appLayout,
  fixedHeight,
  ...props
}: AppContainerProps) {
  const bgGradient = useGradient('background');
  const appLayoutProps = {
    as: Grid,
    height: 'full',
    sx: {
      grid: `
        "banner" auto
        "content-header" auto
        "content-body" 1fr
        "content-footer" auto
        "footer" auto
      `,
      '@media(min-height: 650px)': fixedHeight
        ? {
            maxHeight: 'calc(100 * var(--vh))',
          }
        : {},
    },
  };

  return (
    <Container
      p={0}
      maxW="container.xl"
      {...props}
      {...(appLayout ? appLayoutProps : {})}
      bg={bgGradient}
    />
  );
}
