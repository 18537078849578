import { useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  chakra,
  Flex,
  Grid,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  SVGIconComponentType,
  useFadeEntryFadeExit,
  useResponsive,
} from '@arena-labs/strive2-ui';

import { UserHighRecharge } from '../icons/user-high-recharge';
import { UserLowRecharge } from '../icons/user-low-recharge';

type ACIEstimateValue = 'below' | 'above';

export function Estimate({
  estimate,
  persistSelection,
}: {
  estimate?: ACIEstimateValue;
  persistSelection: (selection: ACIEstimateValue) => void;
}) {
  const [selection, setSelection] = useState<ACIEstimateValue | null>(
    estimate ?? null,
  );
  const analytics = useAnalytics();

  const handleSelectOption = (selection: ACIEstimateValue) => {
    setSelection(selection);
  };

  const handleSubmitSelection = (selection: ACIEstimateValue) => {
    analytics.logEvent(DataTrackingEvent.ACIInnerStateConfirmed, {
      state: selection,
    });
    persistSelection(selection);
  };

  const { scope } = useFadeEntryFadeExit();

  return (
    <Flex ref={scope} flexGrow={1}>
      <Flex direction={'column'} h={'full'}>
        <Text textStyle={'copy_bold'} color={'logo'} mt={10}>
          1 of 3
        </Text>

        <Text textStyle={'h5'} mt={4} mb={10}>
          Reflect on your recharge status over the last 24 hours.
        </Text>

        <VStack spacing={4} mb={'auto'}>
          <RechargeEstimateOption
            onClick={() => handleSelectOption('below')}
            key={'Less than most days'}
            label={'Less than most days'}
            icon={UserLowRecharge}
            selected={selection === 'below'}
            selectedBg={{
              border: 'linear-gradient(254.14deg, #632D0F 0.68%, #FF5B00 100%)',
              background: 'warning.200',
            }}
          />
          <RechargeEstimateOption
            onClick={() => handleSelectOption('above')}
            key={'More than most days'}
            label={'More than most days'}
            icon={UserHighRecharge}
            selected={selection === 'above'}
            selectedBg={{
              border: 'linear-gradient(254.14deg, #146B6B 0.68%, #95FDFD 100%)',
              background: 'positive.200',
            }}
          />
        </VStack>

        <Button
          mt={'auto'}
          isDisabled={!selection}
          variant={'primary'}
          w={'full'}
          onClick={() => {
            selection && handleSubmitSelection(selection);
          }}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

function RechargeEstimateOption({
  label,
  icon,
  selected,
  selectedBg,
  onClick,
}: {
  label: string;
  icon: SVGIconComponentType;
  selected: boolean;
  selectedBg: { border: string; background: string };
  onClick: ButtonProps['onClick'];
}) {
  const rs = useResponsive();
  const background = selected ? selectedBg.background : 'neutral.100';
  return (
    <Box rounded={'card'} bg={background} shadow={'1dp'} w={'full'}>
      <chakra.button onClick={onClick} aria-pressed={selected}>
        <Grid
          alignItems={'center'}
          gap={8}
          templateColumns={'auto 1fr'}
          px="5"
          py={2}
          rounded={'card'}
        >
          <Box>
            <Icon as={icon} boxSize={rs({ xs: '85px', base: '112px' })} />
          </Box>
          <Text textStyle={'button'} gridColumn={2}>
            {label}
          </Text>
        </Grid>
      </chakra.button>
    </Box>
  );
}
