import { Button, Fade, Flex, Grid } from '@chakra-ui/react';

import { NotificationTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { useAppStateChange } from '@arena-labs/strive2-ui';
import {
  DeclinedNotificationPermissionModal,
  useEnablePushNotifications,
  usePushPermissions,
} from '@strive/notifications';

type EnableNotificationActionProps = {
  children: React.ReactNode;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
  onCompleted: () => void;
  state: 'loading' | 'ready';
};

export function EnableNotificationAction({
  children,
  onCompleted,
  state,
  onSecondaryButtonClick = onCompleted,
  secondaryButtonText = 'I want to decide later',
}: EnableNotificationActionProps) {
  const enableNotifications = useEnablePushNotifications({ onCompleted });

  const pushPermissions = usePushPermissions();
  useAppStateChange((isActive) => {
    if (isActive) pushPermissions.refetch();
  });

  const analytics = useAnalytics();

  return (
    <Grid h="full" templateRows="1fr auto" gap="4">
      {children}
      <Fade in={state === 'ready'}>
        <Flex direction="column" gap={6} textAlign="center" px={6}>
          <Button
            variant="underline-link"
            color="neutral.600"
            textStyle="copy"
            fontWeight="bold"
            w="full"
            onClick={() => onSecondaryButtonClick()}
          >
            {secondaryButtonText}
          </Button>

          <Button
            isLoading={enableNotifications.isLoading}
            variant="primary"
            w="full"
            onClick={() => {
              analytics.logEvent(
                NotificationTrackingEvent.AllowNotificationsClicked,
              );
              enableNotifications.mutate();
            }}
          >
            Allow Notifications
          </Button>
        </Flex>
      </Fade>

      <DeclinedNotificationPermissionModal
        isOpen={
          enableNotifications.isError && pushPermissions.data === 'denied'
        }
        onClose={enableNotifications.reset}
        onContinue={() => {
          enableNotifications.reset();
          onCompleted();
        }}
      />
    </Grid>
  );
}
