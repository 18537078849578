import * as React from 'react';

export function UserLowRecharge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 95 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.3667 34.2246C77.6119 30.356 79.5385 22.1571 75.6699 15.9118C71.8012 9.6666 63.6023 7.74 57.3571 11.6087C51.1118 15.4773 49.1852 23.6762 53.0539 29.9215C56.9225 36.1667 65.1215 38.0933 71.3667 34.2246Z"
        fill="#FF5C00"
      />
      <path
        d="M34.2428 1.50281C21.9854 4.36362 14.9307 7.7169 4.57111 15.9328C-2.54692 22.5181 0.0579025 28.5785 2.97014 33.1479C3.31777 33.6936 3.64884 34.1978 3.97459 34.6572L15.0294 54.8348L9.06481 82.2374C8.19693 86.2256 10.7261 90.1618 14.7137 91.0303C15.2434 91.1456 15.7719 91.2012 16.2928 91.2012C19.6928 91.2012 22.7534 88.8399 23.5066 85.3814L29.3405 58.5806C28.9563 58.6587 28.5619 58.7042 28.1611 58.7042C27.5717 58.7042 26.9852 58.6173 26.4177 58.4458C23.2341 57.4851 21.4256 54.1141 22.3857 50.9311L24.9988 42.2701L19.0496 31.4133C22.4708 29.2921 26.2267 27.5096 29.9164 25.966L34.7506 9.94157C35.5132 7.41538 37.8869 5.65184 40.5225 5.65184C41.1113 5.65184 41.6978 5.73875 42.2659 5.91019C45.4489 6.87089 47.2574 10.2419 46.2973 13.4249L44.1577 20.5175C48.9068 18.2314 52.3115 13.4793 50.9406 9.20435C49.3526 4.2507 43.1693 -0.524998 34.2428 1.50281Z"
        fill="#FF5C00"
      />
      <path
        d="M41.8906 7.15467C39.3898 6.4003 36.7513 7.81622 35.9963 10.317L23.6303 51.3065C22.8759 53.8073 24.2918 56.4458 26.7926 57.2008C27.2484 57.3379 27.7077 57.4036 28.16 57.4036C30.192 57.4036 32.069 56.084 32.6862 54.039L45.0523 13.0495C45.8067 10.5481 44.3914 7.90904 41.8906 7.15467Z"
        fill="#FF5C00"
      />
      <path
        d="M82.6885 9.9599C90.0714 9.68617 94.6372 13.163 94.3682 8.84431C94.082 4.25543 85.3435 5.36806 82.6885 9.9599Z"
        fill="#FF5C00"
      />
      <path
        d="M82.6832 0.54984C79.8898 -1.78835 75.7645 3.84045 76.9551 7.87065C80.8239 3.47804 85.3117 2.75028 82.6832 0.54984Z"
        fill="#FF5C00"
      />
      <path
        d="M89.2053 18.268C89.5996 15.1069 83.5073 14.7433 81.1106 17.529C86.1724 18.2899 88.8346 21.2429 89.2053 18.268Z"
        fill="#FF5C00"
      />
      <path
        d="M66.6216 60.3696C66.8314 60.3513 67.0419 60.3229 67.2535 60.2815C70.4561 59.6507 72.5406 56.544 71.911 53.3409C71.2796 50.1383 68.1723 48.0532 64.9697 48.684C64.9047 48.697 64.8426 48.7171 64.7782 48.7319L64.5961 40.9482L63.7105 40.9227L63.5302 47.3455L63.383 52.5906L64.8675 52.5634L64.8024 49.7954C67.2937 49.5471 69.6118 51.2285 70.1055 53.734C70.6092 56.2927 69.0248 58.7734 66.5364 59.4284C66.5264 59.3681 66.5222 59.3084 66.5104 59.2487C66.2656 58.0054 65.6561 56.9277 64.816 56.1024C65.1465 56.6202 65.3919 57.2049 65.5172 57.844C66.0575 60.5866 64.2727 63.247 61.5302 63.7879C58.7882 64.3277 56.1272 62.5423 55.5868 59.8003C55.3308 58.4997 55.6028 57.2197 56.2472 56.1733C55.7713 56.6581 55.3846 57.2221 55.0944 57.8352C53.9256 57.1482 53.0423 55.9877 52.7597 54.5528C52.2347 51.8912 53.9687 49.3077 56.6303 48.7833C57.5555 48.6012 58.4689 48.6964 59.2919 49.0038L58.4772 51.692L59.9008 52.1159L61.1264 47.1705L62.7084 40.7856L61.847 40.5728L59.724 47.579C58.5824 47.0558 57.2747 46.8684 55.9481 47.1291C52.5859 47.7913 50.3961 51.0541 51.0583 54.4174C51.4691 56.5055 52.8845 58.1402 54.703 58.9212C54.486 59.7684 54.4429 60.6741 54.6232 61.5898C55.27 64.8728 58.4541 67.01 61.7365 66.3638C64.644 65.7903 66.6482 63.2275 66.6216 60.3696Z"
        fill="#FF5C00"
      />
    </svg>
  );
}

const MemoUserLowRecharge = React.memo(UserLowRecharge);
export default MemoUserLowRecharge;
