import { Flex, Text } from '@chakra-ui/react';
import { useReducedMotion } from 'framer-motion';

import {
  Icon,
  MotionBox,
  TargetIcon,
  useGradient,
  useResponsive,
} from '@arena-labs/strive2-ui';
import { IncentiveType } from '@strive/api';

import { CompetitionStar } from './competition-star';

type Leaderboard = IncentiveType<'competition'>['leaderboard'];

export type CompetitionLeadersProps = {
  leaders: Leaderboard;
};
export function CompetitionLeaders({ leaders }: CompetitionLeadersProps) {
  const responsive = useResponsive();
  return (
    <Flex direction="column" gap={responsive({ xs: 0, base: 4 })}>
      {leaders?.map((entry) => {
        return <LeaderEntry key={entry.name} entry={entry} />;
      })}
    </Flex>
  );
}

type LeaderEntryProps = {
  entry: NonNullable<Leaderboard>[number];
};
function LeaderEntry({ entry }: LeaderEntryProps) {
  const gradient = useGradient('primary.20');
  const highlightUserStyles = {
    bgGradient: gradient,
    borderBlock: '1px solid',
    borderColor: 'primary.200',
  };
  const animate = !useReducedMotion();
  return (
    <MotionBox
      display="grid"
      layout={animate}
      gap={5}
      gridTemplateColumns="auto 1fr auto"
      py={'2px'}
      px={4}
      {...(entry.me && highlightUserStyles)}
      alignItems={'center'}
    >
      <CompetitionStar size={'list'} positionValue={entry.rank} />

      <Text textStyle={'copy'}>
        {entry.me ? `${entry.name} (Me)` : entry.name}
      </Text>

      <Flex direction="row" gap="1" alignItems="center">
        <Icon as={TargetIcon} boxSize="4" color={'neutral.400'} />

        <Text
          textStyle={'copy'}
          sx={{
            fontVariantNumeric: 'tabular-nums',
          }}
        >
          {entry.score}
        </Text>
      </Flex>
    </MotionBox>
  );
}
