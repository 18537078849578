import { useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  Box,
  Flex,
  Grid,
  Icon,
  IconButton,
  Portal,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { max } from 'lodash-es';

import { CloseIcon, PopoverTriangle } from '../icons';
import { MotionBox } from './motion-box';
import { useEducationPopoverViewed } from './use-education-popover-viewed';
import { ZStack } from './z-stack';

export function ProductEducationPopover({
  placement,
  children,
  id,
  shouldRender,
  shouldOutline,
  title,
  body,
  arrowOffsetFromLeft,
  anchorXPaddingModifier,
}: {
  children: React.ReactNode;
  id: string;
  placement: 'top' | 'bottom';
  shouldRender: boolean;
  shouldOutline?: boolean;
  title: string;
  body: string;
  arrowOffsetFromLeft?: string;
  anchorXPaddingModifier?: number;
}) {
  // using the refs with useState seems to be more stable
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);
  const [arrowRef, setArrowContentRef] = useState<HTMLDivElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const anchorRefRect = anchorRef?.getBoundingClientRect();

  const [popoverViewed, togglePopoverViewed] = useEducationPopoverViewed(id);
  const popoverIsAbove = placement === 'top';

  if (popoverViewed || !shouldRender) {
    return (
      <Box as={MotionBox} w={'full'} transition={'all 0.5s ease-in-out'}>
        {children}
      </Box>
    );
  }

  const borderProps = {
    borderRadius: 'md',
    border: '4px solid',
    borderColor: 'neutral.white',
    py: 2,
  };

  const popoverVariants = {
    hidden: { opacity: 0 },
    showPopover: {
      opacity: 1,
      transition: {
        duration: 1.3,
        delay: 0.3,
      },
    },
    showBG: {
      opacity: 0.7,
      transition: {
        duration: 1.3,
        delay: 0.3,
      },
    },
    showAnchor: {
      opacity: 1,
      transition: {
        duration: 1.3,
        delay: 0.3,
      },
    },
  };
  const platform = Capacitor.getPlatform();

  return (
    //the zstack layers the anchor and a Portal
    <ZStack opacity={0} w={'full'}>
      {/* portal contains:
       - background dimming layer
       - a second instance of the anchor, so that it can easily render over the top of the bg dimming layer
       - the triangle shape that points at anchors
       - the popover content
       */}
      <Portal>
        {/* the full screen dark background layer */}
        <Box
          as={motion.div}
          variants={popoverVariants}
          initial="hidden"
          animate="showBG"
          exit={'hidden'}
          onClick={togglePopoverViewed}
          zIndex={100000}
          position={'absolute'}
          top={0}
          left={0}
          w={'full'}
          h={'full'}
          bg={'neutral.black'}
        ></Box>

        {anchorRefRect?.top && anchorRefRect.top !== 0 && (
          <Box
            w={`100%`}
            zIndex={100001}
            position={'absolute'}
            left={0}
            top={`${max([platform === 'ios' ? 115 : 80, anchorRefRect.top])}px`}
          >
            <Box position={'relative'} px={4 + (anchorXPaddingModifier ?? 0)}>
              {/* abosulutely positioned anchor */}
              <Box
                mx={'auto'}
                w={`full`}
                as={motion.div}
                variants={popoverVariants}
                initial={'showAnchor'}
                animate="showAnchor"
                exit={'hidden'}
                {...(shouldOutline ? borderProps : null)}
                transition={'all 1s ease'}
                onClick={togglePopoverViewed}
              >
                {children}
              </Box>

              {/* position Icon container absolutely above/below anchor ({children}) based on placement prop */}
              <Box
                w={`full`}
                as={motion.div}
                variants={popoverVariants}
                initial="hidden"
                animate="showPopover"
                exit={'hidden'}
                ref={setArrowContentRef}
                position={'absolute'}
                top={
                  popoverIsAbove
                    ? `calc(-${arrowRef?.clientHeight}px)`
                    : `calc(100% + 5px)`
                }
                left={0}
              >
                <Box
                  position={'relative'}
                  w={'full'}
                  pl={arrowOffsetFromLeft ? arrowOffsetFromLeft : '50%'}
                >
                  {/* position the popover content above/below triangle icon based on placement prop */}
                  <Box
                    ref={setContentRef}
                    w={'100%'}
                    position={'absolute'}
                    top={
                      popoverIsAbove
                        ? `calc(-${contentRef?.clientHeight}px + 6px)`
                        : 'calc(100% - 10px)'
                    }
                    //   center the popover content by moving it left the same distance that the anchor is away from the left edge
                    left={0}
                    px={2}
                  >
                    {/* popover content */}
                    <Flex
                      w={'full'}
                      borderRadius={'card'}
                      bg={'primary.600'}
                      direction={'column'}
                      p={4}
                      textAlign={'left'}
                      gap={3}
                      shadow={'24dp'}
                    >
                      <Grid
                        templateColumns={'1fr  auto'}
                        w="full"
                        alignItems={'center'}
                      >
                        <Text textStyle={'copy_bold'} color={'neutral.white'}>
                          {title}
                        </Text>
                        <IconButton
                          as={CloseIcon}
                          aria-label={'Close Popover'}
                          boxSize={6}
                          bg={'transparent'}
                          onClick={togglePopoverViewed}
                          color={'neutral.300'}
                        />
                      </Grid>

                      <Text
                        textStyle={'copy_small'}
                        color={'neutral.100'}
                        lineHeight={'tall'}
                      >
                        {body}
                      </Text>
                    </Flex>
                  </Box>

                  <Icon
                    as={PopoverTriangle}
                    w={'40px'}
                    h={'auto'}
                    transform={`${
                      popoverIsAbove
                        ? 'translateX(-50%)'
                        : 'translateX(-50%) rotate(180deg)'
                    }`}
                    opacity={1}
                    color={'primary.600'}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Portal>

      {/* you dont actually see the children here, but we need it here to measure its getBoundingClientRect() */}
      <Box w={'full'} ref={setAnchorRef} opacity={0}>
        {children}
      </Box>
    </ZStack>
  );
}
