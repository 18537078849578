import { get } from 'lodash-es';

import { addAlpha, elevation, HexColor } from './colors-new';

export type LinearGradient = {
  angle: string;
  stops: Array<{ color: HexColor; position?: string; hint?: string }>;
};

const primaryGradientColors = ['#315B66', '#10272D'] as const;

const imageFade = elevation['1dp']
  .split('')
  .filter((char, index) => index !== 0)
  .join('');

export const gradients = {
  primary: {
    100: {
      angle: 'to bottom',
      stops: [
        { color: primaryGradientColors[0] },
        { color: primaryGradientColors[1] },
      ],
    },
    60: {
      angle: 'to bottom',
      stops: [
        { color: addAlpha(primaryGradientColors[0], 0.6) },
        { color: addAlpha(primaryGradientColors[1], 0.6) },
      ],
    },
    40: {
      angle: 'to bottom',
      stops: [
        { color: addAlpha(primaryGradientColors[0], 0.4) },
        { color: addAlpha(primaryGradientColors[1], 0.4) },
      ],
    },
    20: {
      angle: 'to bottom',
      stops: [
        { color: addAlpha(primaryGradientColors[0], 0.2) },
        { color: addAlpha(primaryGradientColors[1], 0.2) },
      ],
    },
    10: {
      angle: 'to right',
      stops: [
        { color: addAlpha(`#${imageFade}`, 0.9), position: '0%' },
        { color: addAlpha(`#${imageFade}`, 0.8), position: '70%' },
        { color: addAlpha(`#${imageFade}`, 0.5), position: '100%' },
      ],
    },
  },

  secondary: {
    light: {
      angle: '206deg',
      stops: [
        { color: '#D0E1E5', position: '22.4%' },
        { color: '#A2BDC3', position: '79%' },
      ],
    },
    heavy: {
      angle: '236deg',
      stops: [
        { color: '#7EA7B1', position: '16.92%' },
        { color: '#46646B', position: '82.64%' },
      ],
    },
  },

  tertiary: {
    100: {
      // TODO: Check with Kai where this goes
      angle: 'to bottom',
      stops: [{ color: '#55473FE5' }, { color: '#110A07E5' }],
    },
  },

  black_white: [
    {
      // similar to launchpad.200
      angle: '168deg',
      stops: [
        { color: addAlpha('#575757', 0.4), position: '0%' },
        { color: addAlpha('#C1C1C1', 0.4), position: '33%' },
        { color: addAlpha('#575757', 0.4), position: '66%' },
        { color: addAlpha('#C1C1C1', 0.4), position: '100%' },
      ],
    },
  ],
  blue_white: [
    {
      // was launchpad.300
      angle: '168deg',
      stops: [
        { color: addAlpha('#005E76', 0.4), position: '0%' },
        { color: addAlpha('#A8C8D1', 0.4), position: '33%' },
        { color: addAlpha('#005E76', 0.4), position: '66%' },
        { color: addAlpha('#A8C8D1', 0.4), position: '100%' },
      ],
    },
  ],

  blue_orange: [
    {
      // was secondary.300
      angle: '168deg',
      stops: [
        { color: addAlpha('#968379', 0.4), position: '0%' },
        { color: addAlpha('#00627B', 0.4), position: '33%' },
        { color: addAlpha('#968379', 0.4), position: '66%' },
        { color: addAlpha('#00627B', 0.4), position: '100%' },
      ],
    },
  ],

  purple_white: [
    {
      // was launchpad.100
      angle: '168deg',
      stops: [
        { color: addAlpha('#553282', 0.3), position: '0%' },
        { color: addAlpha('#D8C8EC', 0.3), position: '33.8%' },
        { color: addAlpha('#553282', 0.3), position: '65.9%' },
        { color: addAlpha('#D8C8EC', 0.3), position: '100%' },
      ],
    },
  ],

  StriveWare: {
    bgLightGradient: {
      angle: '180deg',
      stops: [
        { color: '#3FD4D4', position: '-23.82%' },
        { color: '#0D6880', position: '100%' },
      ],
    },
    bgDarkGradient: {
      angle: '360deg',
      stops: [
        { color: '#0D6880', position: '0%' },
        { color: '#3597B0', position: '123.82%' },
      ],
    },
  },

  //pretty sure this is new
  navigation: {
    angle: '180deg',
    stops: [
      { color: '#FFFFFF', position: '25.76%' },
      { color: '#717171', position: '128.79%' },
    ],
  },
  //background: linear-gradient(90deg, #DBD9E2 0%, #D9E4E9 100%);

  background: {
    angle: '90deg',
    stops: [
      { color: '#DBD9E2', position: '0%' },
      { color: '#D9E4E9', position: '100%' },
    ],
  },

  //must check and replace @ HRV stuff
  hrv: {
    midBlueGradient: {
      angle: '360deg',
      stops: [
        { color: '#0496FF', position: '0%' },
        { color: '#28B5E0', position: '100%' },
      ],
    },
    graphBlueGradient: {
      angle: '180deg',
      stops: [
        { color: '#25CED8', position: '51.65%' },
        { color: '#00F1FF', position: '156.25%' },
      ],
    },
  },
} as const satisfies Record<
  string,
  | LinearGradient
  | Array<LinearGradient>
  | Record<string, LinearGradient | Array<LinearGradient>>
>;

export const gradientsCSS = Object.fromEntries(
  Object.entries(gradients).map(([color, variants]) => {
    if (isSimpleGradient(color as GradientKey)) {
      // Handle simple gradients
      return [color, getGradient(color as SimpleGradient)];
    } else {
      // Handle multi-gradients
      return [
        color,
        Object.fromEntries(
          Object.keys(variants).map((variant) => [
            variant,
            getGradient(`${color}.${variant}` as GradientLookup),
          ]),
        ),
      ];
    }
  }),
);

export type GradientKey = keyof typeof gradients;
export type GradientSubKey<K extends GradientKey> =
  keyof (typeof gradients)[K] extends infer SubKey
    ? SubKey extends string | number
      ? SubKey
      : never
    : never;
export type SimpleGradient = {
  [Key in GradientKey]: (typeof gradients)[Key] extends
    | LinearGradient
    | Array<LinearGradient>
    ? Key
    : never;
}[GradientKey];
export type MultiGradient = Exclude<GradientKey, SimpleGradient>;

function isLinearGradient(gradient: unknown): gradient is LinearGradient {
  return Boolean(
    typeof gradient === 'object' &&
      gradient &&
      'stops' in gradient &&
      Array.isArray(gradient.stops) &&
      'angle' in gradient &&
      typeof gradient.angle === 'string',
  );
}

export function isSimpleGradient(key: GradientKey): key is SimpleGradient {
  const gradientData = gradients[key];
  return (
    isLinearGradient(gradientData) ||
    (Array.isArray(gradientData) && isLinearGradient(gradientData[0]))
  );
}

export function isMultiGradient(key: GradientKey): key is MultiGradient {
  const gradientData = gradients[key];
  return typeof gradientData == 'object' && !isSimpleGradient(key);
}

export type GradientLookup =
  | SimpleGradient
  | {
      [Key in MultiGradient]: `${Key}.${GradientSubKey<Key>}`;
    }[MultiGradient];

export function getGradient(key: GradientLookup, angle?: string) {
  const gradientData = get(gradients, key);
  if (!gradientData) {
    return undefined;
  }
  return createGradient(gradientData, angle);
}

// Function to convert LinearGradient to CSS gradient
export function createGradient(
  inputGradients: LinearGradient | Array<LinearGradient>,
  angle?: string,
): string {
  const gradients = ([] as Array<LinearGradient>).concat(inputGradients);

  return gradients
    .map((linearGradient) => {
      let gradient = `linear-gradient(${angle ?? linearGradient.angle}`;

      for (let stop of linearGradient.stops) {
        let colorStop = `, ${stop.color}`;

        if (stop.position) {
          colorStop += ` ${stop.position}`;
        }

        if (stop.hint) {
          colorStop += ` ${stop.hint}`;
        }

        gradient += colorStop;
      }

      gradient += ')';

      return gradient;
    })
    .join(',');
}
