import { useState } from 'react';
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';

import { SwipeableDrawerBody } from '@arena-labs/strive2-ui';

import { AdminConsoleLogs } from './admin-console-logs';
import { AdminDatabase } from './admin-database';
import { AdminInfo } from './admin-info';
import { AdminReactQuery } from './admin-react-query';
import { AdminStorage } from './admin-storage';
import { AdminTools } from './admin-tools';
import { AdminXState } from './admin-xstate';

export type AdminModalProps = Omit<DrawerProps, 'children'>;

export function AdminModal(props: AdminModalProps) {
  const tabs = [
    {
      label: 'Info',
      component: AdminInfo,
    },
    {
      label: 'Tools',
      component: AdminTools,
    },
    {
      label: 'Console',
      component: AdminConsoleLogs,
    },
    {
      label: 'Storage',
      component: AdminStorage,
    },
    {
      label: 'Database',
      component: AdminDatabase,
    },
    {
      label: 'API Data',
      component: AdminReactQuery,
    },
    {
      label: 'XState',
      component: AdminXState,
    },
  ] as const;

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Drawer placement="bottom" {...props} onClose={props.onClose}>
      <DrawerOverlay />
      <DrawerContent
        height="clamp(75vh, 40rem, 100vh)"
        color="neutral.black"
        bgGradient="linear(125deg, #C8E6C9 0%, #90CAF9 100%)"
      >
        <DrawerHeader mt="0" pb="0">
          <Heading as="h2" fontSize="h2">
            Admin Dashboard
          </Heading>
          <Text textStyle={'copy_small_thin'} mt="1">
            — With great power comes great responsibility.
          </Text>
          <DrawerCloseButton />
        </DrawerHeader>
        <SwipeableDrawerBody
          onSwipe={props.onClose}
          px="0"
          h="50vh"
          bgGradient="linear(65deg, #F8BBD0 0%, #B3E5FC 50%, #C8E6C9 100%)"
        >
          <Tabs
            index={tabIndex}
            onChange={(idx) => setTabIndex(idx)}
            h="full"
            maxH="full"
            display="grid"
            gridTemplateRows="auto 1fr"
          >
            <TabList
              position="sticky"
              top="0"
              bg="strive.modal.bg"
              zIndex="1"
              px="2"
              py="1"
              maxW="full"
              overflowX="auto"
            >
              {tabs.map((tab, idx) => (
                <Tab key={idx} px="2">
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            <TabPanels overflowY="auto">
              {tabs.map((tab, idx) => (
                <TabPanel key={idx} maxH="100%">
                  <tab.component onSuccess={props.onClose} />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </SwipeableDrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
