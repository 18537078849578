import { useToken } from '@chakra-ui/react';

export default function GaugeArc() {
  const [neutral400, primary400] = useToken('colors', [
    'neutral.400',
    'primary.400',
  ]);
  return (
    <svg viewBox="0 0 36 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 24a16.55 16 0 1 1 30 0"
        stroke={neutral400}
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M3 24a16.55 16 0 1 1 30 0"
        className="scoreIndicator"
        stroke={primary400}
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      {/* <defs>
        <linearGradient
          id="competition-gauge-gradient"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#0D6880" />
          <stop offset="123.82%" stopColor="#3597B0" />
        </linearGradient>
      </defs> */}
    </svg>
  );
}
