import { Box, BoxProps } from '@chakra-ui/react';

import { Program } from '../../api/programs';

export type ProgramProgressBarProps = {
  program: Program;
} & BoxProps;

export function ProgramProgressBar({
  program,
  ...boxProps
}: ProgramProgressBarProps) {
  return (
    <Box
      w="full"
      h="1"
      bg="black"
      borderRadius="sm"
      overflow="hidden"
      {...boxProps}
    >
      <Box
        h="100%"
        w={`${program.percent_complete ?? 1}%`}
        borderRadius="sm"
        bg={'primary.700'}
      ></Box>
    </Box>
  );
}
