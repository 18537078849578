import { useQuery } from '@tanstack/react-query';

import { useHomepage } from '@arena-labs/strive2-coaching';

//todo delete this
export function useAciImage() {
  const [homepage] = useHomepage();

  const aciImageUrl =
    homepage?.learning_session?.aci?.image ?? homepage?.aci?.image;

  return useQuery({
    enabled: !!aciImageUrl,
    queryKey: ['aci', 'image', aciImageUrl],
    queryFn: async () => {
      return new Promise<string>((resolve, reject) => {
        if (aciImageUrl) {
          const img = new Image();
          img.src = aciImageUrl;
          img.onload = () => resolve(aciImageUrl);
          img.onerror = (error) => reject(error);
        } else {
          reject('No image url provided');
        }
      });
    },
  });
}
