import { makeApi, ZodiosResponseByAlias } from '@zodios/core';
import { z } from 'zod';

import { zIsoDateTime } from '../lib/schema-utils';
import { pillarPracticeSchema } from './pillars.schema';
import { wearableSchema } from './user-profile';

export type BiometricsResponse = ZodiosResponseByAlias<
  typeof biometricsApi,
  'getBiometrics'
>;

const BiometricSchema = z.object({
  last_updated: zIsoDateTime,
  last_synced: zIsoDateTime.nullable(),
  value_status: z.enum(['ok', 'not ok']),
  action_status: z.enum(['locked', 'ready', 'completed']),
  hint: z.string(),
  value: z.number(),
  target: z.number(),
  baseline_value: z.number(),
  buffer: z.number(),
  direction: z.enum(['up', 'down']),
  practice: pillarPracticeSchema,
  lessons_before_practice: z.number(),
});

export const BiometricsResponseSchema = z.object({
  wearable: z.array(wearableSchema).nullable(),
  hours_of_sleep: BiometricSchema.nullable(),
  rhr: BiometricSchema.nullable(),
  hrv: BiometricSchema.nullable(),
});

const resultSchema = z.object({
  date: z.string(),
  efficiency: z.number().nullable(),
  consistency: z.number().nullable().catch(null),
  sleep_consistency_14days_avg: z.number(),

  hours_of_sleep: z.number().nullable(),
  hours_of_sleep_14days_avg: z.number(),
  hrv: z.number().nullable(),
  hrv_14days_avg: z.number().nullable(),

  rhr: z.number().nullable(),
  rhr_14days_avg: z.number(),

  sleeps: z
    .array(
      z.object({
        start: zIsoDateTime,
        end: zIsoDateTime,
        start_flatten: zIsoDateTime.nullable(),
        end_flatten: zIsoDateTime.nullable(),
      }),
    )
    .nullable()
    .catch([]),
  sleep_end_14days_avg: z.number(),
  sleep_start_14days_avg: z.number(),

  start_flatten: zIsoDateTime.nullable(),
  end_flatten: zIsoDateTime.nullable(),
});

const responseSchema = z
  .object({
    count: z.number(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    results: z.array(resultSchema),
  })
  .transform((data) => {
    return {
      ...data,
      results: data.results
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .slice(0, 8),
    };
  });

// Converts a JStyleHrv to an API StrivewareHrvRecord

const jstyleToStrivewareHrv = z
  .object({
    date: z.string(),
    hrv: z.number(),
    heartRate: z.number(),
    highBP: z.number(),
    lowBP: z.number(),
    stress: z.number(),
    vascularAging: z.number(),
  })
  .transform((data) => ({
    measured_at: data.date,
    hrv: data.hrv,
    heart_rate: data.heartRate,
    high_bp: data.highBP,
    low_bp: data.lowBP,
    stress: data.stress,
    vascular_aging: data.vascularAging,
  }));

// Converts a JStyleSleep to an API StrivewareSleepRecord
const jstyleToStrivewareSleep = z
  .object({
    startTime: z.string(),
    duration: z.number(),
    quality: z.array(z.number()),
    unitLength: z.number(),
  })
  .transform((data) => ({
    measured_at: data.startTime,
    duration: data.duration,
    quality: data.quality,
    unit_length: data.unitLength,
  }));

//change path to /progress?
export const biometricsApi = makeApi([
  {
    alias: 'getBiometrics',
    description: `Get the user's biometrics`,
    method: 'get',
    path: '/data/',
    response: BiometricsResponseSchema,
  },
  {
    alias: 'saveStrivewareRecords',
    description: 'save striveware hrv & sleep data',
    method: 'post',
    path: '/data/striveware/records/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          hrvs: z.array(jstyleToStrivewareHrv).optional().nullable(),
          sleeps: z.array(jstyleToStrivewareSleep).optional().nullable(),
        }),
      },
    ],
    response: z.unknown(),
  },
  {
    alias: 'getProgressData',
    description: `Get the data for progress tab`,
    method: 'get',
    path: '/data/progress-baseline/',
    response: responseSchema,
  },
]);
