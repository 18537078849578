import { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  AlertBox,
  SwipeableDrawerBody,
  useDeviceInfo,
  useGradient,
} from '@arena-labs/strive2-ui';
import { frontend } from '@strive/api';

import { useAppInfo } from '../../lib/use-app-info';
import { ContactForm, useContactForm } from './contact-form';
import { ContactFormData } from './contact-form-schema';
import { GuestContactForm, useGuestContactForm } from './guest-contact-form';
import { GuestContactFormData } from './guest-contact-form-schema';

export type ContactProps = {
  guestMode?: boolean;
} & Omit<DrawerProps, 'children'>;

export function ContactModal({ guestMode, ...props }: ContactProps) {
  const analytics = useAnalytics();
  const bgGradient = useGradient('background');

  // Log analytics when the modal is opened
  useEffect(() => {
    if (props.isOpen) {
      analytics.logEvent(GeneralTrackingEvent.HelpModalOpen);
    }
  }, [analytics, props.isOpen]);

  // Set up the form.
  // Note: We can disable rules of hooks for this line because we are always
  // calling exactly one useForm hook.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formContext = guestMode ? useGuestContactForm() : useContactForm();
  const { isSubmitting, isSubmitSuccessful } = formContext.formState;

  const handleClose = () => {
    props.onClose();
    formContext.reset();
  };

  const [submitFailed, setSubmitFailed] = useState(false);
  const status = isSubmitting
    ? 'submitting'
    : isSubmitSuccessful
    ? 'success'
    : submitFailed
    ? 'error'
    : 'idle';

  const deviceInfo = useDeviceInfo();
  const appInfo = useAppInfo();
  const onSubmit = async (data: ContactFormData | GuestContactFormData) => {
    setSubmitFailed(false);
    try {
      await frontend.post(`/api/contact-us`, {
        ...data,
        deviceInfo,
        appInfo,
        sentryReplayId: Sentry.getReplay()?.getReplayId(),
      });
      analytics.logEvent(GeneralTrackingEvent.HelpRequested);
    } catch (error) {
      console.error('Error submitting contact form', error);
      setSubmitFailed(true);
    }
  };

  return (
    <Drawer placement="top" {...props} onClose={handleClose}>
      <DrawerOverlay bg={bgGradient} />
      <DrawerContent h={'full'}>
        <DrawerHeader bg={bgGradient}>
          <Heading as="h2" fontSize="2xl">
            Welcome to Strive Support
          </Heading>
          <Text textStyle={'copy'} mt="1">
            We&apos;re here. Let us know how we can help!
          </Text>
          <DrawerCloseButton />
        </DrawerHeader>
        <SwipeableDrawerBody
          onSwipe={handleClose}
          h={'auto'}
          pt={10}
          bg={bgGradient}
        >
          <Stack direction="column" spacing="6">
            {status === 'success' ? (
              <AlertBox
                showIcon
                status="success"
                title="MESSAGE SENT"
                description="Thanks for letting us know. We'll be in touch soon! (Our usual reply time: 1 business day)"
              />
            ) : (
              <>
                {guestMode ? (
                  <GuestContactForm
                    id="help-form"
                    context={
                      formContext as ReturnType<typeof useGuestContactForm>
                    }
                    onSubmit={formContext.handleSubmit(onSubmit)}
                  />
                ) : (
                  <ContactForm
                    id="help-form"
                    context={formContext as ReturnType<typeof useContactForm>}
                    onSubmit={formContext.handleSubmit(onSubmit)}
                  />
                )}
                {status === 'error' && (
                  <AlertBox
                    showIcon
                    status="error"
                    title="ERROR"
                    description="Hmmm… something’s not quite right. Try us again later."
                  />
                )}
              </>
            )}
          </Stack>
        </SwipeableDrawerBody>
        <DrawerFooter justifyContent="space-between" bg={bgGradient}>
          <Button type="button" variant="secondary" p="2" onClick={handleClose}>
            CLOSE
          </Button>
          {status !== 'success' && (
            <Button
              type="submit"
              isLoading={status === 'submitting'}
              form="help-form"
              variant="primary"
            >
              SEND
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
