import PhoneInputWithCountry, {
  Props,
} from 'react-phone-number-input/react-hook-form';
import { Box, BoxProps, Input } from '@chakra-ui/react';

export type PhoneNumberInputProps<InputComponentProps, FormValues> = Props<
  InputComponentProps,
  FormValues
> & {
  sx?: BoxProps['sx'];
};

export function PhoneNumberInput<InputComponentProps, FormValues>({
  sx,
  ...props
}: PhoneNumberInputProps<InputComponentProps, FormValues>) {
  return (
    <Box
      sx={{
        ...sx,
        '.PhoneInputInput': {
          background: 'neutral.50',
          padding: '20px 10px',
          shadow: '1dp',
          border: 'none',
          textStyle: 'copy',
        },
        '.PhoneInputCountry': {
          padding: '8px 15px',
          background: 'neutral.50',
          borderRadius: '6px',
          shadow: '1dp',
          marginRight: '16px',
        },
        '.PhoneInputCountryIcon': {
          height: 'auto',
          width: '20px',
          color: 'pink',
          marginRight: '6px',
        },
        '--PhoneInputCountrySelectArrow-color': 'neutral.700',
        '--PhoneInputCountrySelectArrow-opacity': '1',
      }}
    >
      <PhoneInputWithCountry
        rules={{ required: true }}
        defaultCountry="US"
        inputComponent={Input}
        autoComplete="tel"
        {...props}
      />
    </Box>
  );
}
