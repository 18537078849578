import { useState } from 'react';
import {
  Box,
  Button,
  Fade,
  Flex,
  Icon,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import {
  ChevronLeftIcon,
  CloseIcon,
  useGradient,
} from '@arena-labs/strive2-ui';

import { AppBanner } from '../../../layout/app-banner';
import { CompetitionBackground } from './competition-background';
import CompetitionLeaderboard from './competition-leaderboard';
import CompetitionScore from './competition-score';
import CompetitionWelcome from './competition-welcome';

export type CompetitionInformationProps = {
  onClose?: () => void;
  onNavigateBack?: () => void;
  onProceed: () => void;
};

export default function CompetitionInformation({
  onClose,
  onProceed,
  onNavigateBack,
}: CompetitionInformationProps) {
  const bgGradient = useGradient('background');
  const [currentView, setCurrentView] = useState(0);
  const [isCompact] = useMediaQuery('(max-height: 750px)');

  let excellenceViews = [
    {
      title: undefined,
      component: <CompetitionWelcome />,
      buttonText: 'Introduce Me!',
    },
    {
      title: 'Game and Rules',
      component: <CompetitionBackground />,
      buttonText: 'Calculate Score!',
    },
    {
      title: 'Calculate My Score',
      component: <CompetitionScore explanationFocus={'Practice_Consistency'} />,
      buttonText: 'Continue',
    },
    {
      title: 'Calculate My Score',
      component: <CompetitionScore explanationFocus={'HRV_Avg'} />,
      buttonText: 'Track My Progress!',
    },
    {
      title: 'Calculate My Score',
      component: <CompetitionLeaderboard />,
      buttonText: 'Close',
    },
  ];

  const handleBack = () =>
    currentView > 0
      ? setCurrentView(currentView - 1)
      : onNavigateBack
      ? onNavigateBack()
      : null;

  return (
    <Flex h="100%" pb="6" direction={'column'} bg={bgGradient}>
      <AppBanner color={'neutral.800'}>
        {onNavigateBack || currentView > 0 ? (
          <Button
            gridColumn="1"
            onClick={() => handleBack()}
            variant={'unstyled'}
            size={'xs'}
          >
            <Icon as={ChevronLeftIcon} boxSize={4} mt={'2px'} />
          </Button>
        ) : null}

        <Box gridColumn="2" textAlign={'center'} pl={8} w={'full'}>
          {excellenceViews?.[currentView]?.title && (
            <Text textStyle={'h5'} w={'full'}>
              {excellenceViews?.[currentView]?.title}
            </Text>
          )}
        </Box>

        {onClose && (
          <Flex
            gridColumn="right"
            alignItems={'center'}
            justifyContent={'flex-end'}
            w="full"
            as="button"
            onClick={() => onClose()}
            pr={2}
          >
            <Icon as={CloseIcon} boxSize={'5'} />
          </Flex>
        )}
      </AppBanner>

      <Flex h="100%" px="6" direction={'column'}>
        <Fade
          in
          key={currentView}
          transition={{ enter: { duration: 1 } }}
          style={{ height: '100%' }}
        >
          {excellenceViews[currentView]?.component}
        </Fade>

        <Button
          mb={isCompact ? '0' : '6'}
          variant={'primary'}
          mt="auto"
          w="full"
          onClick={() => {
            if (currentView === excellenceViews.length - 1) {
              onProceed();
            } else {
              setCurrentView(currentView + 1);
            }
          }}
        >
          {excellenceViews[currentView]?.buttonText}
        </Button>
      </Flex>
    </Flex>
  );
}
