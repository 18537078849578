import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Icon,
} from '@chakra-ui/react';

import { CloseIcon, StriveLogoNew, useGradient } from '@arena-labs/strive2-ui';
import { useSuspendMedia } from '@strive/av';

import { AppMenu } from '../app-menu';
import { useMenu } from './use-menu';

export function MenuDrawer() {
  const { isOpen, onClose } = useMenu();
  const bgGradient = useGradient('background');
  useSuspendMedia(isOpen);

  return (
    <Drawer
      placement={'left'}
      onClose={onClose}
      isOpen={isOpen}
      colorScheme="transparent"
    >
      <DrawerContent>
        <DrawerBody m={0} p={0} h={'full'}>
          <Box
            px={4}
            layerStyle={'4dp'}
            h={'full'}
            pt={'calc(env(safe-area-inset-top) + 24px)'}
            w={'full'}
            bg={bgGradient}
          >
            <Flex
              alignItems={'center'}
              justify={'space-between'}
              gap={3}
              mb={10}
            >
              <Icon as={StriveLogoNew} w={'119px'} h={'auto'} color={'logo'} />
              <Button variant={'unstyled'} onClick={onClose} mt={2}>
                <Icon as={CloseIcon} boxSize={8} />
              </Button>
            </Flex>

            <AppMenu />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
