import { useEffect, useState } from 'react';
import Link from 'next/link';
import {
  Box,
  Flex,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import {
  CycleIcon,
  StatArrowIcon,
  TargetIcon,
  TrendChartIcon,
  ZStack,
} from '@arena-labs/strive2-ui';
import { IncentiveType } from '@strive/api';

import { useIncentiveHub } from '../use-incentive-hub';
import { CompetitionStar } from './competition-star';
import GaugeArc from './gauge-arc';
import GaugeConnector from './gauge-connector';

type DataPointSchema = IncentiveType<'competition'>['score'];

export type CompetitionScoreGaugeProps = {
  showConnectorTree?: boolean;
  hrv_above_avg: DataPointSchema;
  consistency: DataPointSchema;
  score: DataPointSchema;
  position: number | null;
  linkStats?: boolean;
  explain?: 'Practice_Consistency' | 'HRV_Avg';
};
export default function CompetitionScoreGauge({
  showConnectorTree = true,
  hrv_above_avg,
  consistency,
  score,
  position,
  linkStats,
  explain,
}: CompetitionScoreGaugeProps) {
  const [pathLength, setPathLength] = useState(0);
  const incentiveHub = useIncentiveHub();
  useEffect(() => {
    const length = document
      .querySelector<SVGPathElement>('.scoreIndicator')
      ?.getTotalLength();
    setPathLength(length ? length : 0);
  }, []);

  return (
    <Flex direction="column">
      <ZStack
        mx="auto"
        w="240px"
        px={'auto'}
        sx={{
          '.scoreIndicator': {
            strokeDasharray: `${(score.value / 100) * pathLength} ${
              (1 - score.value / 100) * pathLength
            }`,
          },
        }}
      >
        <Box opacity={explain ? 0.25 : 1}>
          <GaugeArc />
        </Box>

        <VStack mx={'auto'} spacing={1} mt="auto">
          <Box opacity={explain ? 0.25 : 1}>
            <CompetitionStar size="gauge" positionValue={position ?? 0} />
          </Box>
          <Flex
            gridTemplateColumns={'1fr 24px'}
            pl={'24px'}
            alignItems={'center'}
          >
            <Text textStyle={'h1'}>{score.value}</Text>

            <Icon
              as={StatArrowIcon}
              boxSize={6}
              transform={`rotate(${score.trend === 'up' ? '0' : '180'})`}
              color={score.trend === 'up' ? 'positive.300' : 'warning.200'}
            />
          </Flex>
          <Flex direction={'row'} gap={2} alignItems={'center'}>
            <Icon as={TargetIcon} boxSize={5} color="neutral.700" />

            <Text textStyle={'copy_small'} color={'neutral.700'}>
              My Score
            </Text>
          </Flex>
        </VStack>
      </ZStack>

      {showConnectorTree && (
        <Box w={170} mx="auto" mt="2" mb={'0'} opacity={explain ? 0.25 : 1}>
          <GaugeConnector />
        </Box>
      )}

      {/* percentages */}
      <Flex
        mt={2}
        direction={'row'}
        justify={'space-between'}
        px="8"
        pointerEvents={linkStats ? 'auto' : 'none'}
      >
        <LinkBox
          as="article"
          onClick={() => incentiveHub.onClose()}
          border={explain === 'Practice_Consistency' ? '1px solid' : 'none'}
          borderColor={
            explain === 'Practice_Consistency' ? 'warning.400' : 'none'
          }
          rounded={'md'}
          opacity={explain === 'HRV_Avg' ? 0.25 : 1}
        >
          <VStack spacing={1} mx={explain ? 2 : 0} my={explain ? 2 : 0}>
            <Flex alignItems={'center'}>
              <Text textStyle={'copy'}>{consistency.value}%</Text>
              <Icon
                as={StatArrowIcon}
                boxSize={'18px'}
                transform={`rotate(${
                  consistency.trend === 'up' ? '0' : '180'
                }deg)`}
              />
            </Flex>
            <LinkOverlay as={Link} href="/practices">
              <Flex direction={'row'} gap={'4px'}>
                <Icon as={CycleIcon} boxSize={4} color="neutral.700" />
                <Text textStyle={'copy_small'}>Tool Consistency</Text>
              </Flex>
            </LinkOverlay>
          </VStack>
        </LinkBox>

        <LinkBox
          as="article"
          onClick={() => incentiveHub.onClose()}
          border={explain === 'HRV_Avg' ? '1px solid' : 'none'}
          borderColor={explain === 'HRV_Avg' ? 'warning.400' : 'none'}
          rounded={'md'}
          opacity={explain === 'Practice_Consistency' ? 0.25 : 1}
        >
          <VStack spacing={1} mx={explain ? 2 : 0} my={explain ? 2 : 0}>
            <Flex alignItems={'center'}>
              <Text textStyle={'copy'} variant={'link'} as="a">
                {hrv_above_avg.value}%
              </Text>

              <Icon
                as={StatArrowIcon}
                boxSize={'18px'}
                transform={`rotate(${
                  hrv_above_avg.trend === 'up' ? '0' : '180'
                }deg)`}
              />
            </Flex>
            <LinkOverlay as={Link} href="/progress">
              <Flex direction={'row'} gap={'4px'}>
                <Icon as={TrendChartIcon} boxSize={4} color="neutral.700" />
                <Text textStyle={'copy_small'}>HRV Above Avg.</Text>
              </Flex>
            </LinkOverlay>
          </VStack>
        </LinkBox>
      </Flex>
    </Flex>
  );
}
