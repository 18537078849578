import React, { useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  Button,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import { useACIGate } from '@arena-labs/strive2-data';
import { useUserProfile } from '@strive/api';

import { AdminCapgoChannel } from './admin-capgo-channel';
import { AdminDeleteData } from './admin-delete-data';
import { AdminEnvironments } from './admin-environments';
import { AdminFactoryReset } from './admin-factory-reset';
import { AdminGenerateData } from './admin-generate-data';
import { AdminImpersonate } from './admin-impersonate';
import { AdminTeleport } from './admin-teleport';
import { AdminTestPush } from './admin-test-push';
import { AdminUndo } from './admin-undo';

type AdminToolConfig = {
  label: string;
  enabled: boolean;
  size?: ModalProps['size'];
} & (
  | {
      component: React.ComponentType;
    }
  | {
      component: React.ComponentType<{
        formId: string;
        onSuccess: () => void;
      }>;
      formId: string;
    }
);

export type AdminToolsProps = {
  onSuccess?: () => void;
};

export function AdminTools({ onSuccess }: AdminToolsProps) {
  const { data: user } = useUserProfile();
  const tools = [
    {
      label: 'Change App Channel',
      component: AdminCapgoChannel,
      enabled: Capacitor.isNativePlatform(),
      size: 'full',
    },
    {
      label: 'Change API Environment',
      component: AdminEnvironments,
      formId: 'admin-form-environment',
      enabled: !!user?.is_staff,
    },
    {
      label: 'Impersonate User',
      component: AdminImpersonate,
      formId: 'admin-form-impersonate',
      enabled: !!user?.is_staff,
    },
    {
      label: 'Teleport',
      component: AdminTeleport,
      formId: 'admin-form-teleport',
      enabled: !!user?.is_staff,
    },
    {
      label: 'Test Push Notification',
      component: AdminTestPush,
      enabled: !!user?.is_staff,
    },
    {
      label: 'Delete User Data',
      component: AdminDeleteData,
      formId: 'admin-form-delete-data',
      enabled: !!user?.is_staff,
    },
    {
      label: 'Reset User',
      component: AdminFactoryReset,
      formId: 'admin-form-factory-reset',
      enabled: !!user?.is_staff,
    },
    {
      label: 'Generate Data',
      component: AdminGenerateData,
      formId: 'admin-form-generate-data',
      enabled: !!user?.is_staff,
    },
    {
      label: 'Undo last action',
      component: AdminUndo,
      formId: 'admin-form-undo',
      enabled: !!user?.is_staff,
    },
  ] as const satisfies Readonly<Array<AdminToolConfig>>;

  const [activeIdx, setActiveIdx] = useState<number | null>(null);
  const activeTool = tools[activeIdx ?? -1];
  const [isBusy, setIsBusy] = useState(false);
  const aciGate = useACIGate();
  const onModalClose = () => {
    setActiveIdx(null);
    setIsBusy(false);
    aciGate.actions.reset();
  };

  return (
    <>
      <Grid templateColumns="repeat(auto-fit, minmax(150px, auto))" gap="4">
        {tools.map((tool, idx) =>
          tool.enabled ? (
            <Button
              key={tool.label}
              bg={`positive.300`}
              onClick={() => setActiveIdx(idx)}
              textStyle={'copy'}
              fontWeight={'bold'}
              color={'neutral.700'}
            >
              {tool.label}
            </Button>
          ) : null,
        )}
      </Grid>
      <Modal
        isCentered
        isOpen={!!activeTool}
        onClose={onModalClose}
        scrollBehavior="inside"
        variant="default"
        size={activeTool && 'size' in activeTool ? activeTool?.size : undefined}
      >
        <ModalOverlay />
        <ModalContent p="4" m="0">
          <ModalHeader>
            <Heading as="h2" fontSize="2xl" textAlign="center" tabIndex={0}>
              {activeTool?.label}
            </Heading>
          </ModalHeader>
          <ModalBody m="0" p="0">
            {activeTool &&
              ('formId' in activeTool ? (
                <activeTool.component
                  formId={activeTool.formId}
                  onSuccess={() => {
                    setActiveIdx(null);
                    onSuccess?.();
                  }}
                />
              ) : (
                <activeTool.component />
              ))}
          </ModalBody>
          <ModalFooter
            justifyContent="space-between"
            flexDirection="column"
            gap="4"
            pt="10"
            px="0"
          >
            <Flex justifyContent="space-between" w="full">
              <Button variant="outline" px="8" onClick={onModalClose}>
                Close
              </Button>
              {activeTool && 'formId' in activeTool && (
                <Button
                  type="submit"
                  form={activeTool.formId}
                  px="8"
                  isLoading={isBusy}
                >
                  {activeTool.label}
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
