import { useMemo } from 'react';
import { Box, Center, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { produce } from 'immer';
import { groupBy } from 'lodash-es';

import { ContentLabelSkill } from '@arena-labs/shared-models';
import { CheckboxButton } from '@arena-labs/strive2-ui';

import { useSkills } from '../../api/skills';
import { FilterSection } from './filter-section';

const introCategory = 'Introduction';
export interface FilterSectionSkillsProps {
  selected: Set<string>;
  onChange: (skills: Set<string>) => void;
}

export function FilterSectionSkills({
  selected,
  onChange,
}: FilterSectionSkillsProps) {
  const { data: skills } = useSkills();
  const grouped = useMemo(
    () => groupBy(skills?.results, (skill) => skill.category),
    [skills],
  );

  if (!skills) {
    return null;
  }

  const handleToggle = (skillName: string) => () => {
    const updated = produce(selected, (draft) => {
      if (draft.has(skillName)) {
        draft.delete(skillName);
      } else {
        draft.add(skillName);
      }
    });
    onChange(updated);
  };

  const categories = Object.keys(grouped).filter(
    (category) => category !== introCategory,
  );

  const getCategoryButtons = (skills: ContentLabelSkill[]) => (
    <>
      {skills.map((skill) => (
        <CheckboxButton
          size="sm"
          key={skill.slug}
          name={skill.slug}
          isChecked={selected.has(skill.slug)}
          onChange={handleToggle(skill.slug)}
        >
          <Center textAlign="center">{skill.name}</Center>
        </CheckboxButton>
      ))}
    </>
  );

  return (
    <FilterSection
      name="FOCUS AREA"
      applied={selected.size}
      onClear={() => onChange(new Set())}
    >
      <VStack spacing={3}>
        {grouped[introCategory] ? (
          <SimpleGrid columns={1} spacingY={1} width="100%">
            {getCategoryButtons(grouped[introCategory])}
          </SimpleGrid>
        ) : null}
        {categories.map((cat) => {
          const skills = grouped[cat];
          if (!skills) return null;

          return (
            <Box as="fieldset" key={cat} width="100%">
              <Text
                as="legend"
                textStyle={'copy_small'}
                textTransform="uppercase"
                mb={1}
              >
                {cat}
              </Text>
              <SimpleGrid columns={2} spacingX={2} spacingY={1}>
                {getCategoryButtons(skills)}
              </SimpleGrid>
            </Box>
          );
        })}
      </VStack>
    </FilterSection>
  );
}
