import NextLink from 'next/link';
import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Link,
  SimpleGrid,
  Text,
  useBreakpointValue,
  VStack,
  WrapItem,
} from '@chakra-ui/react';

import {
  PlayableVideo,
  Video,
  VideoPageMetadata,
} from '@arena-labs/shared-models';

import { isProgramDetail, Program } from '../api/programs';
import { ProgramHeader } from '../components/program/program-header';
import { ProgramStats } from '../components/program/program-stats';
import { ProgramVideoCard } from '../components/program/program-video-card';

export type ProgramDetailProps = {
  program: Program;
  getVideoHref: (
    video: Video | string,
    meta: VideoPageMetadata | undefined,
  ) => string;
  onClickVideo?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    video: PlayableVideo,
  ) => void;
  showDescription?: boolean;
  thumbnailPosition?: string;
};

export function ProgramDetail({
  program,
  getVideoHref,
  onClickVideo,
  showDescription = true,
  thumbnailPosition,
}: ProgramDetailProps) {
  const isListView = useBreakpointValue({ base: true, md: false });
  const videoPageMeta = { program: program?.slug };

  return program ? (
    <>
      <VStack
        spacing="4"
        alignItems="flex-start"
        gridArea="content-body"
        overflowY="auto"
        overflowX={'hidden'}
        pb="4"
      >
        {showDescription ? (
          <ProgramHeader program={program} w="full" />
        ) : (
          <Box px="6" w="full">
            <Text textStyle={'copy_bold'} py="4">
              Playlist
            </Text>
            <ProgramStats program={program} />
          </Box>
        )}

        {isProgramDetail(program) && (
          <Box w="full">
            {isListView ? (
              <VStack
                as="ul"
                role="list"
                listStyleType="none"
                spacing="4"
                px="6"
              >
                {program.videos.map((video, index) => (
                  <chakra.li
                    key={video.slug}
                    width="100%"
                    tabIndex={-1}
                    data-video={video.slug}
                    display="flex"
                  >
                    <Flex w="full" position="relative" bg={'neutral.50'}>
                      <ProgramVideoCard
                        program={program}
                        video={video as Video}
                        href={getVideoHref(video as Video, videoPageMeta)}
                        onClick={onClickVideo}
                        index={index + 1}
                        direction="row"
                        thumbnailPosition={thumbnailPosition}
                      />
                    </Flex>
                  </chakra.li>
                ))}
              </VStack>
            ) : (
              <SimpleGrid
                as="ul"
                minChildWidth="250px"
                justifyItems="start"
                spacingX="2"
                spacingY="8"
                w="full"
              >
                {program.videos.map((video, index) => (
                  <WrapItem
                    key={video.slug}
                    tabIndex={-1}
                    data-video={video.slug}
                    position="relative"
                  >
                    <ProgramVideoCard
                      program={program}
                      video={video as Video}
                      href={getVideoHref(video as Video, videoPageMeta)}
                      onClick={onClickVideo}
                      index={index + 1}
                      direction="column"
                      thumbnailPosition={thumbnailPosition}
                    />
                  </WrapItem>
                ))}
              </SimpleGrid>
            )}
          </Box>
        )}
      </VStack>

      {isProgramDetail(program) && program.next_video_slug && (
        <Center w={'full'} mt={6} pb={10}>
          <Button variant={'primary'} px={6} py={3} w={'calc(100% - 48px)'}>
            <Link
              as={NextLink}
              href={getVideoHref(program.next_video_slug, videoPageMeta)}
              onClick={(event) => {
                const upNext = program.next_video ?? program.videos[0];
                if (upNext) {
                  onClickVideo?.(event, upNext);
                }
              }}
              py="2"
              px="4"
            >
              {program.percent_complete
                ? 'CONTINUE WATCHING'
                : 'START WATCHING'}
            </Link>
          </Button>
        </Center>
      )}
    </>
  ) : null;
}
