import { Box, Divider, Flex, Grid, Text } from '@chakra-ui/react';

import CompetitionScoreGauge from '../competition-score-gauge';

export default function CompetitionScore({
  explanationFocus,
}: {
  explanationFocus: 'Practice_Consistency' | 'HRV_Avg';
}) {
  return (
    <Flex direction="column" h="full" gap={10} py={4}>
      <CompetitionScoreGauge
        hrv_above_avg={{
          value: 70,
          trend: 'up',
        }}
        consistency={{
          value: 80,
          trend: 'down',
        }}
        score={{
          value: 75,
          trend: 'up',
        }}
        position={13}
        explain={explanationFocus}
      />
      <Box>
        <Divider px={6} ml={-6} borderColor={'neutral.800'} />
      </Box>
      <Flex direction="column" rowGap={4}>
        <MetricExplanation explanationFocus={explanationFocus} />
      </Flex>
    </Flex>
  );
}

function MetricExplanation({
  explanationFocus,
}: {
  explanationFocus: 'Practice_Consistency' | 'HRV_Avg';
}) {
  return (
    <>
      <Text textStyle={'h3'}>
        {explanationFocus === 'Practice_Consistency'
          ? 'Tool Consistency'
          : 'HRV Above Avg.'}
      </Text>
      <Text textStyle={'copy_small'} color="neutral.700">
        {explanationFocus === 'Practice_Consistency'
          ? 'Unlock and log Tools in your Arena Strive app as consistently as possible.'
          : "Your Heart Rate Variability is critical to success. We'll establish your baseline, and your goal will be to stay above it!"}
      </Text>
      <Grid
        templateColumns={'auto auto 1fr'}
        alignItems={'center'}
        textAlign={'center'}
        columnGap={2}
        mx={2}
      >
        <Text textStyle={'copy_small'}>
          {explanationFocus === 'Practice_Consistency'
            ? 'Tool Consistency'
            : 'HRV Above Avg.'}
        </Text>
        <Text textStyle={'copy_small'} color={'neutral.700'}>
          =
        </Text>
        <Flex w="full" direction={'column'} gap={2} px={2}>
          <Text textStyle={'copy_small'}>
            {' '}
            {explanationFocus === 'Practice_Consistency'
              ? 'Days Practiced'
              : 'Days above HRV Baseline'}
          </Text>
          <Divider borderColor={'neutral.700'} />
          <Text textStyle={'copy_small'}>Total Days on Strive</Text>
        </Flex>
      </Grid>
    </>
  );
}
