import { useMemo } from 'react';
import { Box, BoxProps, VStack } from '@chakra-ui/react';

import { formatMarkup, sanitizeMarkup } from '@arena-labs/strive2-ui';

import { isProgram, Program } from '../../api/programs';
import { ProgramStats } from './program-stats';

export type ProgramHeaderProps = {
  program: Program;
} & BoxProps;

export function ProgramHeader({ program, ...boxProps }: ProgramHeaderProps) {
  const formattedDescription = useMemo(
    () => ({
      __html: sanitizeMarkup(formatMarkup(program.description)),
    }),
    [program],
  );
  return (
    <VStack spacing="2" alignItems="stretch" py="4" px="6" {...boxProps}>
      <Box
        sx={{ p: { marginBottom: 3 } }}
        color="nenutral.600"
        textStyle={'h3'}
        dangerouslySetInnerHTML={formattedDescription}
      />
      {isProgram(program) && <ProgramStats program={program} maxWidth="50ch" />}
    </VStack>
  );
}
