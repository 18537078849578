import * as React from 'react';

export function UserHighRecharge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 90 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.8903 36.6314C91.2319 36.1841 91.9113 45.8256 85.5708 46.2728L67.9016 47.5749C65.2393 47.7627 62.9284 45.7567 62.7407 43.0944C62.6103 41.2483 62.892 38.5944 62.965 36.6682L54.4857 44.4827L70.8525 61.4392C73.7364 64.4372 71.7018 69.3684 67.6565 69.6037L38.9688 72.3594C32.6524 72.956 31.7455 63.3524 38.0614 62.7558L56.9546 60.9409L44.2256 47.7532C43.8637 47.4035 43.558 47.0008 43.3202 46.5571L8.30214 81.7993C3.82597 86.3003 -3.01735 79.4944 1.45852 74.9937L55.9277 20.1758L50.1143 14.2007L41.4889 22.0703C36.795 26.3419 30.2918 19.1959 34.9857 14.9242L47.0592 3.90882C49.0324 2.11311 52.0881 2.25704 53.8838 4.23021L66.1552 16.846C69.1793 19.9547 73.1484 22.7172 72.9799 27.159L72.5864 37.5381L84.8903 36.6314ZM78.4126 0C73.1921 0 68.9602 4.23192 68.9602 9.45234C68.9602 14.6727 73.1921 18.9047 78.4126 18.9047C83.6329 18.9047 87.8648 14.6727 87.8648 9.45234C87.8648 4.23192 83.6329 0 78.4126 0Z"
        fill="#00CC66"
      />
    </svg>
  );
}

const MemoUserHighRecharge = React.memo(UserHighRecharge);
export default MemoUserHighRecharge;
