import { useEffect, useState } from 'react';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';

import { Elevation } from '@arena-labs/strive2-ui';

import { GameAndRulesExpectations } from '../information/competition-background';

export type CompetitionHubCountdownProps = {
  startDate: Date;
};
export function CompetitionHubCountdown({
  startDate,
}: CompetitionHubCountdownProps) {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const startTime = startDate.getTime();

  const timeDiff = startTime - currentTime;
  const minuteInMS = 1000 * 60;
  const hrInMS = minuteInMS * 60;
  const dayInMS = hrInMS * 24;

  const makeDoubleDigits = (value: string) => {
    if (value.length === 1) {
      value = '0' + value;
    }
    return value;
  };

  const daysLeft = makeDoubleDigits(
    Math.abs(Math.floor(timeDiff / dayInMS)).toString(),
  ).split('');
  const msRemaining = timeDiff % dayInMS;
  const hoursLeft = makeDoubleDigits(
    Math.floor(msRemaining / hrInMS).toString(),
  ).split('');
  const msRemainingForMins = msRemaining % hrInMS;
  const minutesLeft = makeDoubleDigits(
    (Math.floor(msRemainingForMins / minuteInMS) % 60).toString(),
  ).split('');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  console.log(daysLeft, hoursLeft, minutesLeft);
  return (
    <>
      <Flex direction={'column'} gap={4} my="auto">
        <Text textStyle={'h2'}>Launch In</Text>
        <Flex
          direction="row"
          gap="2"
          alignItems={'center'}
          w="full"
          color={'neutral.200'}
          textStyle={'h1'}
        >
          {daysLeft.map((value, idx) => {
            return <CountDownBlock key={idx} value={value} />;
          })}
          <Text color={'neutral.700'} textStyle={'h1'}>
            :
          </Text>
          {hoursLeft.map((value, idx) => {
            return (
              <CountDownBlock key={idx} value={value === '-' ? '0' : value} />
            );
          })}
          <Text color={'neutral.700'} textStyle={'h1'}>
            :
          </Text>
          {minutesLeft
            .filter((value) => value !== '-')
            .map((value, idx) => {
              return <CountDownBlock key={idx} value={value} />;
            })}
        </Flex>
        <Flex
          textStyle={'copy_extra_small'}
          justify={'space-around'}
          gap={8}
          px="1"
          mt={-2}
        >
          <Text w="full">Days</Text>
          <Text w="full">Hours</Text>
          <Text w="full">Minutes</Text>
        </Flex>
      </Flex>
      <Divider mx={-6} px={6} mb={6} />
      <Box mb="auto">
        <GameAndRulesExpectations />
      </Box>
    </>
  );
}

function CountDownBlock({ value }: { value: string }) {
  return (
    <Elevation level={'24dp'} as={Flex} rounded="md" w="full">
      <Text textStyle={'h2'} textAlign={'center'} my="4" w="full">
        {value}
      </Text>
    </Elevation>
  );
}
