import { chakra, Divider, Flex, HStack, Text } from '@chakra-ui/react';
import { addDays } from 'date-fns';

import { pluralize, ZStack } from '@arena-labs/strive2-ui';
import { IncentiveType } from '@strive/api';

import { CompetitionLeaders } from './competition-leaders';
import CompetitionScoreGauge from './competition-score-gauge';

export type CompetitionHubLeaderboardProps = {
  competitionData: IncentiveType<'competition'>;
};

export function CompetitionHubLeaderboard({
  competitionData,
}: CompetitionHubLeaderboardProps) {
  const daysUntilCompEnds = Math.round(
    (competitionData.end_date.getTime() - addDays(new Date(), 0).getTime()) /
      (1000 * 60 * 60 * 24),
  );

  return (
    <>
      <CompetitionScoreGauge
        showConnectorTree={false}
        hrv_above_avg={competitionData.hrv_above_avg}
        consistency={competitionData.consistency}
        score={{ value: 66, trend: 'up' }}
        position={13}
        linkStats
      />

      <ZStack w="full">
        <Divider borderColor={'neutral.900'} my={6} mx={-6} px={6} />
        <Flex
          px={4}
          py={3}
          rounded={'md'}
          as={HStack}
          gap={1}
          direction="column"
          w="full"
          bg={'primary.50'}
          zIndex={100000}
        >
          <Text textStyle={'copy_bold'} color={'neutral.800'} mr={'auto'}>
            {daysUntilCompEnds === 0
              ? 'Less than 1 day'
              : `${daysUntilCompEnds}`}{' '}
            <chakra.span color={'neutral.800'} textStyle={'copy_small'}>
              {pluralize(daysUntilCompEnds, ' day')} till program ends
            </chakra.span>
          </Text>
        </Flex>
      </ZStack>

      <CompetitionLeaders leaders={competitionData.leaderboard} />
    </>
  );
}
