import { Box, Flex, Text } from '@chakra-ui/react';

import { Hangout } from '../hangout';

export default function CompetitionWelcome() {
  return (
    <Flex direction="column" h="full" gap="4" justifyContent="space-evenly">
      <Flex direction="column" rowGap="6">
        <Text textStyle={'h1'} as={Flex} flexDirection={'column'}>
          <span>Introducing,</span>
          <span>The Strive</span>
          <span>Leaderboard</span>
        </Text>
        <Text textStyle={'h3'}>
          Each pursuit of performance benefits from some friendly competition!
        </Text>
      </Flex>
      <Box mx="auto" w="230px">
        <Hangout />
      </Box>
    </Flex>
  );
}
