import { useMemo } from 'react';
import NextLink from 'next/link';
import { Box, Flex, Grid, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';

import { NoBreakText, truncate } from '@arena-labs/strive2-ui';

import { Program } from '../../api/programs';
import { ProgramIcon } from './program-icon';
import { ProgramProgressBar } from './program-progress-bar';

export type ProgramCardProps = {
  program: Program;
  href: string;
};

export function ProgramCard({ program, href }: ProgramCardProps) {
  const description = useMemo(
    () => truncate(program.description, 100, 20),
    [program.description],
  );

  return (
    <LinkBox
      as="article"
      bg={'primary.50'}
      shadow="2dp"
      borderRadius="card"
      py="4"
      px="3"
    >
      <Grid gap={3} templateColumns="auto 1fr auto" mb={2}>
        <ProgramIcon program={program} boxSize="8" />
        <Flex direction="column" gap={1}>
          <LinkOverlay as={NextLink} href={href}>
            <Text textStyle={'copy_small'} textTransform={'uppercase'}>
              <NoBreakText as="span" text={program.title} />
            </Text>
          </LinkOverlay>

          <Text
            textStyle={'copy_extra_small'}
            color="neutral.700"
            lineHeight={'tall'}
          >
            {description}
          </Text>
        </Flex>
      </Grid>
      <Box>
        <ProgramProgressBar program={program} mt="2" bg="neutral.200" />
        <Text textStyle={'copy_extra_small'} color="neutral.600" mt="1.5">
          {program.percent_complete
            ? `Program ${Math.round(program.percent_complete)}% complete`
            : 'Program not started'}
        </Text>
      </Box>
    </LinkBox>
  );
}
