import { useForm } from 'react-hook-form';
import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HTMLChakraProps,
  Input,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  GuestContactFormData,
  guestContactFormSchema,
} from './guest-contact-form-schema';

export type ContactFormProps = {
  context: ReturnType<typeof useGuestContactForm>;
} & HTMLChakraProps<'form'>;

export function useGuestContactForm() {
  return useForm<GuestContactFormData>({
    resolver: yupResolver(guestContactFormSchema),
  });
}

export function GuestContactForm(props: ContactFormProps) {
  const { context, onSubmit, ...formProps } = props;
  const {
    register,
    formState: { errors },
  } = props.context;

  return (
    <chakra.form {...formProps} onSubmit={onSubmit}>
      <Stack direction="column" w="full" spacing="6">
        <FormControl id="help-form-name" isInvalid={Boolean(errors.name)}>
          <FormLabel fontWeight="bold" mb="1">
            NAME
          </FormLabel>
          <Input type="text" autoComplete="name" {...register('name')} />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl id="help-form-email" isInvalid={Boolean(errors.email)}>
          <FormLabel fontWeight="bold" mb="1">
            EMAIL
          </FormLabel>
          <Input type="email" autoComplete="email" {...register('email')} />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="help-form-category"
          isInvalid={Boolean(errors.category)}
        >
          <FormLabel fontWeight="bold" mb="1">
            I have feedback or a question about
          </FormLabel>
          <Select {...register('category')}>
            <option value="">Select a category</option>
            <option>Login</option>
            <option>My account</option>
            <option>It&apos;s something else...</option>
          </Select>
          <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
        </FormControl>

        <FormControl id="help-form-message" isInvalid={Boolean(errors.message)}>
          <FormLabel fontWeight="bold" mb="1">
            MESSAGE
          </FormLabel>
          <Textarea
            {...register('message')}
            placeholder="Your message"
            rows={6}
            bg={'information.50'}
          />
          <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
        </FormControl>
      </Stack>
    </chakra.form>
  );
}
