import {
  Button,
  Code,
  Icon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import { HelpCircleIcon } from '../icons';

export type PasswordTipsProps = {
  bg?: string;
  ariaLabel?: string;
};

export function PasswordTips({
  bg = 'gray.600',
  ariaLabel = 'Password tips',
}: PasswordTipsProps) {
  return (
    <Popover>
      <PopoverTrigger>
        <Button
          variant="ghost"
          leftIcon={<Icon as={HelpCircleIcon} boxSize="6" />}
          aria-label={ariaLabel}
          size="xs"
        >
          Password help
        </Button>
      </PopoverTrigger>
      <PopoverContent bg={bg}>
        <PopoverArrow bg={bg} />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="bold">Password help</PopoverHeader>
        <PopoverBody>
          <Text textStyle={'copy'}>
            We strongly encourage the use of a password manager, use the one
            built into your browser! But if you do choose to use your own
            password
          </Text>
          <UnorderedList textStyle={'copy'}>
            <ListItem>
              A memorable and long password, longer than 8 characters is far
              better than a shorter complex password e.g{' '}
              <Code colorScheme="green">1PoweredupClinician!!</Code> is much
              more secure than <Code colorScheme="yellow">P@ssword123</Code>
            </ListItem>
            <ListItem>
              We check for compromised or common passwords that have already
              been cracked or are easily guessed.
            </ListItem>
            <ListItem>
              Don’t use your name in the password as those are easily guessed as
              well.
            </ListItem>
          </UnorderedList>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
