import { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  useCallbackRef,
  useDisclosure,
} from '@chakra-ui/react';
import { identity } from 'lodash-es';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { useCompHubNewPref } from '@arena-labs/strive2-data';
import { CloseIcon, InfoIcon, useGradient } from '@arena-labs/strive2-ui';
import { IncentiveType } from '@strive/api';
import { useChangeEffect } from '@strive/utils';

import { AppBanner } from '../../layout/app-banner';
import { useCurrentIncentive } from '../use-current-incentive';
import { useIncentiveHub } from '../use-incentive-hub';
import { CompetitionHubLeaderboard } from './competition-hub-leaderboard';
import { CompetitionHubCountdown } from './countdown/competition-hub-countdown';
import CompetitionInformation from './information/competition-information';

export type CompetitionHubProps = {
  competition: IncentiveType<'competition'>;
};
export default function CompetitionHub({ competition }: CompetitionHubProps) {
  const bgGradient = useGradient('background');
  const [onlyShowIncentive, setOnlyShowIncentive] = useState(false);
  const incentiveHub = useIncentiveHub();
  const competitionInformation = useDisclosure();

  const [_, setKnowsAboutHub] = useCompHubNewPref();

  // When the competition hub is open, we want to refetch more frequently
  useCurrentIncentive({
    refetchInterval: competition.end_date < new Date() ? false : 15_000,
    staleTime: 30_000,
    enabled: incentiveHub.isOpen,
  });

  // Local state to hold competition data
  // The state will be updated in a deferred manner.
  const [competitionData, setCompetitionData] = useState(competition);
  const updateCompetitionData = useCallbackRef(() =>
    setCompetitionData(competition),
  );

  const { isOpen } = incentiveHub;
  const analytics = useAnalytics();
  useEffect(() => {
    if (isOpen) {
      analytics.logEvent(GeneralTrackingEvent.CompetitionHubOpen);
      // Delay updating competitionData state to allow for modal opening animation
      setTimeout(updateCompetitionData, 1000);
    }
  }, [analytics, isOpen, updateCompetitionData]);

  // Update the modal in real time if it's already open
  useChangeEffect(competition, identity, () => {
    if (isOpen) updateCompetitionData();
  });

  return (
    <>
      <Drawer
        isFullHeight
        isOpen={incentiveHub.isOpen}
        placement="top"
        onClose={() => {
          analytics.logEvent(GeneralTrackingEvent.CompetitionHubClosed);
          incentiveHub.onClose();
        }}
      >
        <DrawerOverlay bg={bgGradient} />
        <DrawerContent>
          <DrawerHeader p={0} bg={bgGradient}>
            <AppBanner bg="none" color={'neutral.800'}>
              <Button
                onClick={() => {
                  if (onlyShowIncentive) {
                    setOnlyShowIncentive(false);
                  }
                  competitionInformation.onOpen();
                }}
                variant={'unstyled'}
                size={'sm'}
              >
                <Icon as={InfoIcon} boxSize={5} />
              </Button>

              <Text textStyle={'h5'} gridColumn={2} textAlign={'center'}>
                Strive Leaderboard
              </Text>

              <Flex
                justifyContent={'flex-end'}
                as="button"
                onClick={() => {
                  analytics.logEvent(GeneralTrackingEvent.CompetitionHubClosed);
                  setKnowsAboutHub(true);
                  incentiveHub.onClose();
                }}
              >
                <Icon as={CloseIcon} boxSize={'5'} />
              </Flex>
            </AppBanner>
          </DrawerHeader>

          <DrawerBody p="0" bg={bgGradient}>
            <Flex h="full" direction={'column'} gap={10} px="6" pt={6}>
              {competitionData.start_date > new Date() ? (
                <CompetitionHubCountdown startDate={competition.start_date} />
              ) : (
                <CompetitionHubLeaderboard competitionData={competitionData} />
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={competitionInformation.isOpen}
        placement="right"
        onClose={competitionInformation.onClose}
        size={'full'}
      >
        <DrawerOverlay bg={bgGradient} />
        <DrawerContent pt="env(safe-area-inset-top)" bg={bgGradient}>
          <DrawerBody p={0}>
            <CompetitionInformation
              onProceed={competitionInformation.onClose}
              onClose={competitionInformation.onClose}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
