import { useEffect } from 'react';
import { chakra, Flex, Text, useCallbackRef } from '@chakra-ui/react';
import { stagger, useAnimate, useReducedMotion } from 'framer-motion';

import { Icon } from '@arena-labs/strive2-ui';

import { ReactComponent as CommunityChatSvg } from './community-chat.svg';

export type NotificationSuccessMetricsProps = {
  onAnimationComplete?: () => void;
};

export function NotificationSuccessMetrics({
  onAnimationComplete,
}: NotificationSuccessMetricsProps) {
  const reducedMotion = useReducedMotion();
  const [scope, animate] = useAnimate();
  const handleAnimationComplete = useCallbackRef(onAnimationComplete);
  useEffect(() => {
    animate([
      [
        '[data-stagger]',
        { opacity: [0, 1] },
        {
          at: 1,
          delay: stagger(0.6),
          duration: 0.5,
        },
      ],
    ]).then(handleAnimationComplete);
  }, [animate, handleAnimationComplete, reducedMotion]);

  return (
    <Flex direction="column" gap="4" w="full" align="start" p="6">
      <Icon size="72px" as={CommunityChatSvg} />
      <Text textStyle="h2">
        Alarm fatigue is real, but prioritizing yourself is worth it.
      </Text>

      <Flex direction="column" gap="4" textStyle="h3" mt="4" ref={scope}>
        <span data-stagger>
          Strivers who{' '}
          <chakra.span color="logo">enable push notifications are</chakra.span>{' '}
        </span>
        <Text textStyle="h1" fontSize={'36px'} color="logo" data-stagger>
          3x
        </Text>
        <span data-stagger>
          <chakra.span color="logo">more successful</chakra.span> at achieving
          their goals.
        </span>
      </Flex>
    </Flex>
  );
}
