import { useEffect } from 'react';
import { Box, BoxProps, Flex, Text, useCallbackRef } from '@chakra-ui/react';
import { useAnimate, useReducedMotion } from 'framer-motion';

import { Icon, useGradient } from '@arena-labs/strive2-ui';

import { ReactComponent as LetsChatSvg } from './lets-chat.svg';

export type NotificationFearOfMissingOutProps = {
  onAnimationComplete?: () => void;
};

export function NotificationFearOfMissingOut({
  onAnimationComplete,
}: NotificationFearOfMissingOutProps) {
  const reducedMotion = useReducedMotion();
  const [scope, animate] = useAnimate();

  const handleAnimationComplete = useCallbackRef(onAnimationComplete);
  useEffect(() => {
    const bubbles = scope.current.children;
    for (let i = 0; i < bubbles.length; i++) {
      const bubble = bubbles[i];
      const isLeft = bubble.dataset.bubble === 'left';
      const startX = reducedMotion ? 0 : isLeft ? -100 : 100;

      const isLastBubble = i === bubbles.length - 1;
      animate(
        bubble,
        { x: [startX, 0], opacity: [0, 1] },
        {
          duration: 0.5,
          delay: i * 0.75,
          type: 'spring',
          stiffness: 350,
          damping: 20,
        },
      ).then(isLastBubble ? handleAnimationComplete : () => void 0);
    }
  }, [animate, handleAnimationComplete, reducedMotion, scope]);

  return (
    <Flex direction="column" gap="4" w="full" align="start" p="6">
      <Icon size="72px" as={LetsChatSvg} color={'neutral.800'} />
      <Text textStyle="h2">We will message you only when it’s important.</Text>
      <Box />
      <Box
        display="flex"
        flexDirection="column"
        gap="6"
        my="auto"
        w="full"
        ref={scope}
      >
        <ChatBubble variant="left">
          Don&apos;t miss out on your coach&apos;s personal insights{' '}
          <span role="img" aria-label="lightbulb emoji">
            💡
          </span>
          !
        </ChatBubble>
        <ChatBubble variant="right">
          Remind me about my Daily Check-In.
        </ChatBubble>
        <ChatBubble variant="left">
          Stay connected to StriveWare{' '}
          <span role="img" aria-label="raised-hands emoji">
            🙌
          </span>
          .
        </ChatBubble>
      </Box>
    </Flex>
  );
}

type ChatBubbleProps = {
  variant: 'left' | 'right';
  children: React.ReactNode;
};
function ChatBubble({ variant, children }: ChatBubbleProps) {
  const leftGradient = useGradient('secondary.light');
  const rightGradient = useGradient('primary.100');

  const styles: BoxProps =
    variant === 'left'
      ? {
          alignSelf: 'start',
          borderBottomLeftRadius: 0,
          bg: leftGradient,
          color: 'black',
        }
      : {
          alignSelf: 'end',
          borderBottomRightRadius: 0,
          bg: rightGradient,
          color: 'white',
        };
  return (
    <Box
      borderRadius="2xl"
      py="2"
      px="3"
      maxWidth="70%"
      layerStyle="8dp"
      textStyle={'copy_small'}
      lineHeight="normal"
      data-bubble={variant}
      {...styles}
    >
      {children}
    </Box>
  );
}
