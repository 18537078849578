import { useState } from 'react';
import { JSONTree } from 'react-json-tree';
import {
  Box,
  Heading,
  List,
  ListItem,
  Select,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { AnyInterpreter, StateValue } from 'xstate';

import { useHomepageService } from '@arena-labs/strive2-coaching';
import { Card } from '@arena-labs/strive2-ui';
import { useAuthService } from '@strive/api';
import { usePushNotificationsService } from '@strive/notifications';

export function AdminXState() {
  const homepageService = useHomepageService();
  const authService = useAuthService();
  const { service: pushNotificationService } = usePushNotificationsService();
  const services = {
    homepageService,
    authService,
    pushNotificationService,
  };

  const [selected, setSelected] = useState<AnyInterpreter>(homepageService);
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const next = services[e.target.value as keyof typeof services];
    if (next) {
      setSelected(next);
    }
  };

  return (
    <VStack spacing="4" align="stretch">
      <Select onChange={onChange}>
        {Object.entries(services).map(([name, service]) => (
          <option key={name} value={name}>
            {service.machine.id}
          </option>
        ))}
      </Select>
      <XStateService service={selected} />
    </VStack>
  );
}

export type XStateServiceProps = {
  service: AnyInterpreter;
};
function XStateService({ service }: XStateServiceProps) {
  const snapshot = service.getSnapshot();
  const states = getStates(snapshot.value).sort();

  return (
    <VStack spacing="4" align="stretch">
      <Card colorScheme="dark" width="full">
        <VStack spacing="2" align="stretch">
          <Card.Heading as="h3" fontSize="h3">
            {snapshot.machine?.id}
          </Card.Heading>
          {snapshot.machine?.description && (
            <Text textStyle={'copy'} fontStyle="italic">
              {snapshot.machine?.description}
            </Text>
          )}
          <List>
            {states.map((s) => (
              <ListItem key={s}>
                {s.length > 1 ? <>&bull;</> : null} {s}
              </ListItem>
            ))}
          </List>
        </VStack>
      </Card>

      <VStack spacing="2" align="stretch">
        <Heading as="h4" fontSize="h4">
          Context
        </Heading>
        <JSONTree data={snapshot.context} hideRoot sortObjectKeys />
      </VStack>

      <VStack spacing="2" align="stretch">
        <Heading as="h4" fontSize="h4">
          Available Events
        </Heading>
        <Box ml="4">
          <UnorderedList>
            {snapshot.nextEvents.map((event) => (
              <ListItem
                key={event}
                whiteSpace="break-spaces"
                overflowWrap="anywhere"
                fontFamily="monospace"
              >
                {event}
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      </VStack>
    </VStack>
  );
}

/**
 * Turns an XState nested state value into a flat array of state names
 */
function getStates(state: StateValue): string[] {
  if (typeof state === 'string') {
    return [state];
  }

  // Recurse into nested states
  return Object.entries(state).map(([key, value]) => {
    return [key].concat(getStates(value)).join('.');
  });
}
