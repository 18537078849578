import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useLatest } from 'react-use';
import {
  Box,
  Button,
  Circle,
  Flex,
  Icon,
  Text,
  useCallbackRef,
  useDisclosure,
  useToken,
  VStack,
} from '@chakra-ui/react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

import {
  formatSeconds,
  PauseIcon,
  PlayIcon,
  useWakeLock,
} from '@arena-labs/strive2-ui';

import { AudioSeekButton } from './media/audio-seek-button';
import { useMediaPlaybackMachine } from './media/media-playback.machine';
import { MediaScrubber } from './media/media-scrubber';
import {
  useMediaIsSuspended,
  useSuspendableMedia,
} from './media/media-suspender';
import { getAudioUrl } from './media/media-url';
import { PlaybackRateButtons } from './media/playback-rate-buttons';
import { TranscriptPopup } from './media/transcript-popup';
import { MediaHandleRef } from './media-handle';

export type AudioPlayerProps = {
  playBackID: string;
  duration: string;
  options?: VideoJsPlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
  onPlayerClosed?: (percent: number) => Promise<unknown>;
  onEnded?: () => unknown;
  allowSeek?: boolean;
  transcript?: string;
  autoPlay?: boolean;
  children?: React.ReactNode | ((player: VideoJsPlayer) => React.ReactNode);
};

const defaultOptions: VideoJsPlayerOptions = {
  controls: true,
  responsive: true,
  fluid: true,
};

export const AudioPlayer = forwardRef<MediaHandleRef, AudioPlayerProps>(
  function AudioPlayer(
    {
      playBackID,
      duration,
      options,
      onReady,
      onPlayerClosed,
      onEnded,
      allowSeek = true,
      transcript,
      autoPlay = true,
      children,
    },
    ref,
  ) {
    const audioRef = useRef<HTMLAudioElement>(null);
    const playerRef = useRef<VideoJsPlayer | null>(null);
    const [player, setPlayer] = useState<VideoJsPlayer>();
    const onReadyRef = useRef<Required<AudioPlayerProps>['onReady']>((player) =>
      onReady?.(player),
    );

    const [progressColor, progressBg] = useToken('colors', [
      'strive.audio.fg',
      'strive.card',
    ]);

    const mediaUrl = getAudioUrl(playBackID);

    // Setting up the video UI
    const optionsRef = useLatest(options);
    const [currentTime, setCurrentTime] = useState(0);
    const listenPercent = (currentTime / parseFloat(duration)) * 100;
    const wakeLock = useWakeLock();

    useEffect(() => {
      const audioElement = audioRef.current;
      if (!audioElement) return;

      const player = (playerRef.current = videojs(
        audioElement,
        {
          ...defaultOptions,
          ...optionsRef.current,
        },
        () => {
          setPlayer(player);
          onReadyRef.current(player);
        },
      ));

      player.mobileUi({ forceForTesting: false });
      player.src(mediaUrl);

      // Set up listeners
      const timeUpdate = (event: Event) => {
        setCurrentTime(audioElement.currentTime);
      };
      audioElement.addEventListener('timeupdate', timeUpdate);
      audioElement.addEventListener('play', (event: Event) =>
        wakeLock.lock(audioElement.duration - audioElement.currentTime),
      );
      audioElement.addEventListener('pause', (event: Event) =>
        wakeLock.release(),
      );

      // Dispose the Video.js player when the functional component unmounts
      return () => {
        audioElement.removeEventListener('timeupdate', timeUpdate);
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    }, [optionsRef, mediaUrl, wakeLock]);

    // Install media player hooks
    const [state, send] = useMediaPlaybackMachine(audioRef, {
      mediaType: 'audio',
      onPlayerClosed: onPlayerClosed,
    });
    const isPlaying = state.matches('playback.playing');

    const handleSeek = (seconds: number) => {
      const player = playerRef.current;
      if (!player) return;

      const currentTime = player.currentTime();
      // clamp the new time between 0s and the duration
      const newTime = Math.min(
        Math.max(currentTime + seconds, 0),
        player.duration(),
      );

      setCurrentTime(newTime);
      player.currentTime(newTime);
    };

    const transcriptDisclosure = useDisclosure();

    const getState = useCallbackRef(() => state);
    useImperativeHandle(ref, () => ({
      player: playerRef.current,
      mediaEl: audioRef.current,
      getState,
      getContext: () => getState().context,
      hide: () => transcriptDisclosure.onClose(),
    }));

    const isSuspended = useMediaIsSuspended();
    useSuspendableMedia(audioRef.current);

    return (
      <Box position="relative">
        <Flex
          direction="column"
          align="stretch"
          justify="space-between"
          gap={6}
          height="inherit"
          maxHeight="350px"
        >
          <div hidden data-vjs-player>
            <audio
              ref={audioRef}
              onEnded={onEnded}
              autoPlay={autoPlay && !isSuspended}
            />
          </div>
          <Flex justifyContent="space-between" alignItems="center">
            {allowSeek && (
              <AudioSeekButton
                seconds={-15}
                aria-label="Rewind 30 Seconds"
                onClick={() => handleSeek(-15)}
                disabled={currentTime === 0}
              />
            )}
            <Circle
              size="135px"
              backgroundImage={`conic-gradient( ${progressColor} 0%, ${progressColor} ${listenPercent}%, ${progressBg} ${listenPercent}%, ${progressBg} 100%)`}
              shadow="md"
            >
              <Circle
                as="button"
                onClick={() => {
                  if (audioRef.current?.paused) {
                    audioRef.current.play();
                    wakeLock.lock();
                  } else {
                    audioRef.current?.pause();
                    wakeLock.release();
                  }
                }}
                size="120px"
                bg="strive.audio.bg"
              >
                <Icon
                  color="strive.audio.fg"
                  marginLeft={isPlaying ? 0 : 4}
                  as={isPlaying ? PauseIcon : PlayIcon}
                  h="50px"
                  w="50px"
                />
              </Circle>
            </Circle>

            {allowSeek && (
              <AudioSeekButton
                seconds={15}
                aria-label="Fast Forward 15 Seconds"
                onClick={() => handleSeek(15)}
                disabled={listenPercent === 100}
              />
            )}
          </Flex>

          {player && (
            <VStack alignItems="stretch">
              <MediaScrubber
                color="strive.audio.fg"
                player={player}
                thumbIconProps={{
                  sx: {
                    circle: {
                      fill: 'strive.modal.bg',
                    },
                  },
                }}
              />
              <Flex justifyContent="space-between">
                <Text textStyle={'copy'}>{formatSeconds(currentTime)}</Text>
                <Text textStyle={'copy'}>
                  -
                  {formatSeconds(
                    Math.floor(player.duration()) - Math.floor(currentTime),
                  )}
                </Text>
              </Flex>
            </VStack>
          )}

          <Flex w="full" alignItems="flex-end">
            {transcript ? (
              <Button
                variant="outline"
                colorScheme="secondary"
                onClick={transcriptDisclosure.onToggle}
              >
                Transcript
              </Button>
            ) : null}
            <Box ml="auto">
              <Text textStyle={'copy'}>Speed</Text>
              <PlaybackRateButtons
                currentRate={state.context.playbackRate}
                onChange={(rate) => send({ type: 'changePlaybackRate', rate })}
              />
            </Box>
          </Flex>

          {transcript ? (
            <TranscriptPopup
              transcript={transcript}
              colorScheme="secondary"
              {...transcriptDisclosure}
            />
          ) : null}
        </Flex>
        {player
          ? typeof children === 'function'
            ? children(player)
            : children
          : null}
      </Box>
    );
  },
);
