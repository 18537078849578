import { useEffect } from 'react';
import { addBreadcrumb } from '@sentry/nextjs';
import { createActorContext } from '@xstate5/react';
import { isEqual } from 'lodash-es';
import { SnapshotFrom } from 'xstate5';

import { useAnalytics } from '@arena-labs/analytics';

import { useWearableMode } from '../lib/use-wearable-mode';
import { strivewareMachine } from './striveware-connection.machine';

type StrivewareSnapshot = SnapshotFrom<typeof strivewareMachine>;

export const StrivewareContext = createActorContext(strivewareMachine);

export function StrivewareProvider(props: { children: React.ReactNode }) {
  const analytics = useAnalytics();
  const { data } = useWearableMode();
  if (data?.mode !== 'striveware') return <>{props.children}</>;

  return (
    <StrivewareContext.Provider
      options={{
        input: {
          wearable: data.wearable,
          analytics,
        },
      }}
    >
      <DebugStriveware />
      {props.children}
    </StrivewareContext.Provider>
  );
}

function DebugStriveware() {
  const state = StrivewareContext.useSelector(
    (state) => state.value,
    (a, b) => isEqual(a, b),
  );
  useEffect(() => {
    console.debug('StrivewareWearable state', state);
    addBreadcrumb({
      type: 'debug',
      category: 'striveware.connection',
      level: 'debug',
      data: state,
    });
  }, [state]);
  return null;
}

export const StrivewareSelector = {
  bluetoothState: (state: StrivewareSnapshot) =>
    state.matches({ Requirement: { Permission: 'Prompt' } })
      ? 'permission.prompt'
      : state.matches({ Requirement: { Permission: 'Denied' } })
      ? 'permission.denied'
      : state.matches('Bluetooth Ready')
      ? 'bluetooth.ready'
      : 'bluetooth.off',
  userDevice: (state: StrivewareSnapshot) => state.context.pairedDevice,
  isConnected: (state: StrivewareSnapshot) => state.hasTag('connected'),
  deviceState: (state: StrivewareSnapshot) =>
    state.hasTag('syncing')
      ? 'syncing'
      : state.matches({
          'Bluetooth Ready': { Paired: { Connected: { Sync: 'Complete' } } },
        })
      ? 'synced'
      : state.matches({
          'Bluetooth Ready': { Paired: { Connected: { Sync: 'Error' } } },
        })
      ? 'sync-failed'
      : state.hasTag('connected')
      ? 'connected'
      : state.hasTag('connecting')
      ? 'connecting'
      : state.hasTag('connectFailed')
      ? 'connect-failed'
      : 'disconnected',
  deviceStatusText: (state: StrivewareSnapshot) =>
    state.hasTag('syncing')
      ? 'Syncing'
      : state.matches({
          'Bluetooth Ready': { Paired: { Connected: { Sync: 'Complete' } } },
        })
      ? 'Connected'
      : state.matches({
          'Bluetooth Ready': { Paired: { Connected: { Sync: 'Error' } } },
        })
      ? 'Sync failed'
      : state.hasTag('connected')
      ? 'Connected'
      : state.hasTag('connecting')
      ? 'Connecting'
      : 'Not Connected',
} satisfies Record<string, Parameters<typeof StrivewareContext.useSelector>[0]>;
