import { MouseEventHandler } from 'react';
import {
  BoxProps,
  Button,
  chakra,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
  VStack,
  WrapItem,
} from '@chakra-ui/react';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ContentTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { GetVideosResponse, Video } from '@arena-labs/shared-models';
import { LoadingOrError } from '@arena-labs/strive2-ui';

import { FilterBar } from '../components/filter/filter-bar';
import { VideoCard } from '../components/video/video-card';
import { ContentSectionType } from '../constants';
import { useContentLibraryStore } from '../state';

export type VideoListProps = {
  section: ContentSectionType;
  heading: React.ReactNode;
  getHref: (video: Video) => string;
  useSectionVideos: () => UseInfiniteQueryResult<GetVideosResponse, AxiosError>;
  disableFilters?: boolean;
  linkTarget?: string;
  variant?: 'list' | 'grid';
} & BoxProps;

export function VideoList({
  section,
  heading,
  getHref,
  disableFilters,
  useSectionVideos,
  linkTarget,
  variant,
  ...boxProps
}: VideoListProps) {
  const query = useContentLibraryStore((state) => state.query[section]);
  const updateQuery = useContentLibraryStore((state) => state.updateQuery);
  const {
    data,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useSectionVideos();
  const analytics = useAnalytics();
  const isNarrow = useBreakpointValue({ base: true, md: false });
  const isListView = variant === 'list' || (!variant && isNarrow);

  if (status !== 'success') {
    return (
      <LoadingOrError
        status={status}
        errorMessage="There was a problem loading the Video Content Library."
        retry={refetch}
      />
    );
  }

  const videoPages = data?.pages ?? [];
  const videos = videoPages.flatMap((page) => page.results);

  const handleViewMore: MouseEventHandler<HTMLButtonElement> = async (
    event,
  ) => {
    event.preventDefault();
    if (!isFetchingNextPage) {
      analytics.logEvent(ContentTrackingEvent.LoadMoreVideos, {
        loadedPages: videoPages.length,
        nextPage: videoPages.length + 1,
      });
      const result = await fetchNextPage();

      // Move focus to the first new item that was loaded
      if (result.isSuccess) {
        const { pages } = result.data;
        const newPage = pages[pages.length - 1];
        const firstNewItem = newPage?.results[0];
        const toFocus = document.querySelector(
          `li[data-video=${firstNewItem?.slug}]`,
        ) as HTMLElement;
        if (toFocus) {
          toFocus.focus();
        }
      }
    }
  };

  return (
    videos && (
      <VStack spacing="2" width="100%" align="stretch" {...boxProps}>
        <Flex alignItems="center" justifyContent="space-between">
          {heading}
          {!disableFilters && (
            <FilterBar
              filters={query}
              onChange={(updated) => updateQuery(section, updated)}
            />
          )}
        </Flex>
        <Text textStyle={'caption'}>{videoPages[0]?.count} VIDEOS</Text>
        {isListView ? (
          <VStack as="ul" role="list" listStyleType="none" spacing="4">
            {videos.map((video) => (
              <chakra.li
                key={video.slug}
                width="100%"
                tabIndex={-1}
                data-video={video.slug}
              >
                <VideoCard
                  video={video}
                  href={getHref(video)}
                  thumbnailPx="120"
                  thumbnailRatio={4 / 3}
                  direction="row"
                  linkTarget={linkTarget}
                />
              </chakra.li>
            ))}
          </VStack>
        ) : (
          <SimpleGrid
            as="ul"
            minChildWidth="200px"
            spacingX="4"
            spacingY="8"
            justifyItems="start"
          >
            {videos.map((video) => (
              <WrapItem key={video.slug} tabIndex={-1} data-video={video.slug}>
                <VideoCard
                  video={video}
                  href={getHref(video)}
                  thumbnailPx="200"
                  thumbnailRatio={4 / 3}
                  direction="column"
                />
              </WrapItem>
            ))}
          </SimpleGrid>
        )}
        {hasNextPage && (
          <Button
            w="100%"
            py="2"
            isLoading={isFetchingNextPage}
            loadingText="LOADING"
            onClick={handleViewMore}
          >
            VIEW MORE
          </Button>
        )}
      </VStack>
    )
  );
}
