import { Box, Button, Flex, Text } from '@chakra-ui/react';

export interface FilterSectionProps {
  name: string;
  applied?: number;
  children: React.ReactNode;
  onClear: () => void;
}

export function FilterSection({
  name,
  applied,
  children,
  onClear,
}: FilterSectionProps) {
  const suffix = applied ? ` (${applied})` : '';
  return (
    <Box as="fieldset" width="100%" minWidth={0}>
      <Flex justifyContent="space-between" mb={1}>
        <Text as="legend" textStyle={'copy_bold'}>
          {name + suffix}
        </Text>
        {applied ? (
          <Button
            variant="unstyled"
            height="auto"
            fontSize="sm"
            onClick={onClear}
          >
            CLEAR
          </Button>
        ) : null}
      </Flex>
      {children}
    </Box>
  );
}
