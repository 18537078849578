import { Divider, Flex, Text, useMediaQuery } from '@chakra-ui/react';

import { CompetitionLeaders } from '../competition-leaders';

export default function CompetitionLeaderboard() {
  const [isCompact] = useMediaQuery('(max-height: 750px)');

  return (
    <Flex direction="column" h="full" gap={10} py={4}>
      <CompetitionLeaders
        leaders={[
          {
            rank: 1,
            score: 92,
            name: 'Cobra',
          },
          {
            rank: 2,
            score: 89,
            name: 'Galaxia',
          },
          {
            rank: 3,
            score: 84,
            name: 'Titan',
          },
          {
            rank: 4,
            score: 81,
            name: 'Maverick',
          },
          {
            rank: 5,
            score: 77,
            name: 'Orion',
          },
          {
            rank: 13,
            score: 75,
            name: 'Scorpios',
            me: true,
          },
        ]}
      />

      <Divider px={6} ml={-6} borderColor={'neutral.900'} />

      <Flex direction="column" rowGap={4}>
        <Text textStyle={'h3'}>Consistency is Key!</Text>
        <Text
          textStyle={'copy'}
          lineHeight={isCompact ? 'tall' : 'taller'}
          color="neutral.700"
        >
          Integrating new behaviors is all about small, daily changes.
        </Text>
      </Flex>
    </Flex>
  );
}
