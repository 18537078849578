import { Button, chakra, Flex, Text } from '@chakra-ui/react';

import { ProfileFoundResponse } from '@arena-labs/shared-models';

export default function EmailFound({
  onClaimEmail,
  onDenyEmail,
  foundProfile,
}: {
  foundProfile: ProfileFoundResponse;
  onClaimEmail: () => void;
  onDenyEmail: () => void;
}) {
  return (
    <Flex direction={'column'} mt={10} h={'full'} px={6}>
      <Text textStyle={'h2'} mb={6}>
        Welcome, {foundProfile.first_name}!
      </Text>
      <Text textStyle={'copy'} color={'neutral.600'}>
        The email <chakra.span color={'logo'}>{foundProfile.email}</chakra.span>{' '}
        is associated with{' '}
        <chakra.span
          color={'logo'}
        >{`${foundProfile.first_name} ${foundProfile.last_name}`}</chakra.span>{' '}
        from <chakra.span color={'logo'}>{foundProfile.team_name}</chakra.span>.
        Is this you?
      </Text>

      <Flex mt={'auto'} direction={'column'} gap={6} textAlign={'center'}>
        <Button variant={'secondary'} w={'full'} onClick={onDenyEmail}>
          No, that&apos;s not me
        </Button>
        <Button variant={'primary'} w={'full'} onClick={onClaimEmail}>
          Yes, That&apos;s Me!
        </Button>
      </Flex>
    </Flex>
  );
}
