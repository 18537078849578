import { useRef, useState } from 'react';
import {
  Flex,
  Slider,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  useToken,
} from '@chakra-ui/react';

import { Icon, SliderThumbIcon } from '@arena-labs/strive2-ui';
import { haptics } from '@strive/device';

import { ACIQuestion } from './aci-questions';

export type CheckInQuestionProps = {
  number: number;
  total: number;
  question: ACIQuestion;
  value?: number | undefined | null;
  onChange: (value: number) => void;
};

export function CheckInQuestion({
  number,
  total,
  question,
  value,
  onChange,
}: CheckInQuestionProps) {
  // `sliderValue` is internal state that tracks the value of the slider
  // can be overridden by `value` prop
  const [sliderValue, setSliderValue] = useState(value);
  const hasValue = sliderValue !== undefined;
  const callouts = question.values;
  const getCalloutIndex = (val: number) => {
    const zoneLength = 101 / callouts.length;
    return Math.floor(val / zoneLength);
  };
  const description =
    sliderValue == null ? '...' : callouts[getCalloutIndex(sliderValue)];

  const [sliderColor, sliderBg] = useToken('colors', [
    'strive.aci.slider',
    'strive.aci.sliderBar',
  ]);

  const containerRef = useRef<HTMLDivElement>(null);

  const onSliderChangeStart = () => {
    haptics.selectionStart();
    haptics.impact('LIGHT');
  };
  const onSliderChange = (value: number) => {
    if (
      sliderValue &&
      getCalloutIndex(value) !== getCalloutIndex(sliderValue)
    ) {
      haptics.selectionChanged();
    }
    setSliderValue(value);
  };
  const onSliderChangeEnd = (value: number) => {
    onChange(value);
    haptics.selectionEnd();
  };

  return (
    <Flex
      direction="column"
      gap="1.5"
      ref={containerRef}
      align="center"
      textAlign="center"
    >
      <Text textStyle={'copy_small'} color="neutral.500">
        {number} of {total}
      </Text>
      <Text textStyle={'h2'}>{question.display}</Text>

      <Slider
        min={0}
        max={100}
        step={1}
        defaultValue={sliderValue ?? undefined}
        onChangeStart={onSliderChangeStart}
        onChange={onSliderChange}
        onChangeEnd={onSliderChangeEnd}
        marginTop={12}
      >
        <SliderTrack rounded="1rem" bg={sliderBg} height="2" />
        <Tooltip
          hasArrow
          py="3"
          px="2"
          mx="3"
          borderRadius="button"
          shadow="none"
          bg="neutral.900"
          placement="top"
          isOpen={hasValue}
          label={<Text textStyle={'copy_small'}>{description}</Text>}
          portalProps={{ containerRef }}
        >
          <SliderThumb boxSize={12} bg="transparent">
            <Icon
              as={SliderThumbIcon}
              color={hasValue ? sliderColor : 'neutral.500'}
              size="full"
            />
          </SliderThumb>
        </Tooltip>
      </Slider>
      <Flex justify="space-between" color="primary.700" width="full">
        <Text textStyle={'copy_small'} maxWidth="40%">
          {question.label.start}
        </Text>
        <Text textStyle={'copy_small'} maxWidth="40%" textAlign="right">
          {question.label.end}
        </Text>
      </Flex>
    </Flex>
  );
}
