import {
  Box,
  Button,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  Icon,
  Stack,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';

import { VideoSorting } from '@arena-labs/shared-models';
import {
  ChevronLeftIcon,
  NavigationDrawer,
  SwipeableDrawerBody,
} from '@arena-labs/strive2-ui';

type SortingOption = VideoSorting & {
  label: string;
};
export interface SortingProps {
  sorting: VideoSorting;
  isOpen: boolean;
  onClose: () => void;
  onApply: (query: VideoSorting) => void;
}

export function Sorting({ sorting, isOpen, onClose, onApply }: SortingProps) {
  const handleSelect = (option: SortingOption) => {
    onApply(Object.assign({}, option));
  };

  const options: SortingOption[] = [
    { label: 'Shortest to longest', field: 'duration', direction: 'asc' },
    { label: 'Longest to shortest', field: 'duration', direction: 'desc' },
    { label: 'Newest to Oldest', field: 'created_at', direction: 'desc' },
    { label: 'Oldest to Newest', field: 'created_at', direction: 'asc' },
    { label: 'Video title A-Z', field: 'title', direction: 'asc' },
    { label: 'Video title Z-A', field: 'title', direction: 'desc' },
  ];

  const selectedIndex = options.findIndex(
    (option) =>
      option.field === sorting.field && option.direction === sorting.direction,
  );
  return (
    <NavigationDrawer
      placement="top"
      onClose={onClose}
      size="full"
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Grid templateColumns="50px 1fr 50px" alignItems="center">
            <Button
              onClick={onClose}
              backgroundColor="transparent"
              p={4}
              ml={-4}
            >
              <VisuallyHidden>
                Apply sorting and go back to media library
              </VisuallyHidden>
              <Icon as={ChevronLeftIcon} />
            </Button>
            <Text fontFamily="brand" textStyle={'copy'} justifySelf="center">
              SORT BY
            </Text>
          </Grid>
        </DrawerHeader>
        <SwipeableDrawerBody
          direction="Up"
          onSwipe={() => onClose()}
          delta={250}
        >
          <Stack direction="column" spacing={0} mx={-6}>
            {options.map((option, id) => (
              <Box
                key={id}
                borderRadius={0}
                borderBottom={1}
                borderStyle="solid"
                borderColor="gray.800"
              >
                <Button
                  onClick={() => handleSelect(option)}
                  w="100%"
                  py={8}
                  fontSize="xl"
                  bgColor="transparent"
                  color={
                    id === selectedIndex
                      ? 'strive.primary'
                      : 'strive.textNatural'
                  }
                  fontWeight={id === selectedIndex ? 'bold' : 'normal'}
                  sx={{
                    '&:active, &:hover': {
                      bgColor: 'logo',
                      color: 'strive.secondary',
                    },
                  }}
                >
                  {option.label}
                </Button>
              </Box>
            ))}
          </Stack>
        </SwipeableDrawerBody>
      </DrawerContent>
    </NavigationDrawer>
  );
}
