import { useState } from 'react';
import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

import { PageStack } from '@arena-labs/strive2-ui';

import { useIncentiveHub } from '../incentives/use-incentive-hub';
import { AppGateProps } from './app-gate-listener';

export function CongratsGate({
  appGate,
  updateGates,
}: AppGateProps<'reward_gate'>) {
  const [isOpen, setIsOpen] = useState(true);
  const incentiveHub = useIncentiveHub();

  const clearGate = () => {
    setIsOpen(false);
    updateGates({
      type: 'reward_gate',
      slug: appGate.slug,
    }).then(() => incentiveHub.onOpen());
  };
  return (
    <Drawer isOpen={isOpen} size="full" placement="bottom" onClose={clearGate}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody>
          <PageStack textAlign={'center'} spacing={0}>
            <Center w="full">
              <Image
                src="/images/incentives/treasure-chest.gif"
                alt="a full treasure box"
                w="280px"
                pb={12}
              />
            </Center>

            <Heading w="full">Congratulations!</Heading>
            <Text textStyle={'copy'}>
              You&apos;ve successfully completed Strive Foundations! The Strive
              team will be communicating with you shortly about pick up!
            </Text>
          </PageStack>
        </DrawerBody>

        <DrawerFooter>
          <VStack w="full" spacing={4} pb="4">
            <Button w="full" variant={'primary'} onClick={clearGate}>
              Awesome!
            </Button>

            <Button
              onClick={clearGate}
              fontSize={'sm'}
              variant="link"
              color={'gray.50'}
            >
              <u>Donate my Treasure Box</u>
            </Button>
          </VStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
