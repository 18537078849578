import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { useFadeEntryFadeExit } from '@arena-labs/strive2-ui';

export function ContextInfo() {
  const { scope } = useFadeEntryFadeExit();

  return (
    <Box ref={scope} h={'full'}>
      <Grid templateRows={'auto auto'} rowGap={8} mt={6}>
        <Text textStyle={'h2'}>What does that mean?</Text>
        <Text
          textStyle={'copy'}
          as={Flex}
          flexDirection={'column'}
          gap={6}
          fontSize={'14px'}
          color={'neutral.600'}
          lineHeight={'taller'}
          fontWeight={500}
        >
          <span>
            High Performance entails refining interoception, honing awareness of
            internal sensations for a robust mind-body connection.
          </span>
          <span>
            The Arena Check-in prompts you to gauge if you feel “recharged” or
            “depleted” over the last 24 hours, aligning perceived recharge with
            nightly Heart Rate Variability data.
          </span>
          <span>
            We simplify choices due to HRV accuracy challenges—only “above” or
            “below” 8 day average — aiding front-line clinicians in adapting to
            challenges for superior performance.
          </span>
        </Text>
      </Grid>
    </Box>
  );
}
