import Link from 'next/link';
import {
  Box,
  chakra,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { groupBy } from 'lodash-es';

import { Coach } from '@arena-labs/shared-models';
import {
  formatCoachName,
  LoadingOrError,
  PersonIcon,
} from '@arena-labs/strive2-ui';

import { useCoachCache, useCoaches } from '../api/coaches';

export interface CoachListProps {
  getHref: (coach: Coach) => string;
}

export function CoachList({ getHref }: CoachListProps) {
  const { data: coachList, status, refetch } = useCoaches();
  const setCoachCache = useCoachCache();

  if (status !== 'success') {
    return (
      <LoadingOrError
        status={status}
        errorMessage={`There was a problem loading the Performance Ambassadors.`}
        retry={refetch}
      />
    );
  }

  const groupedCoaches = groupBy(coachList?.results, 'group');

  return (
    <chakra.section px="8" py="4" width="100%">
      <Heading as="h2" size="md" fontWeight="bold" pb="1" mb="2">
        MEET YOUR PERFORMANCE AMBASSADORS
      </Heading>
      <VStack spacing="8" alignItems="flex-start">
        {Object.keys(groupedCoaches)
          .sort()
          .map((group) => (
            <Box width="100%" key={group}>
              <Heading as="h2" size="sm" fontWeight="bold" pb="1" mb="2">
                {group}
              </Heading>
              <SimpleGrid key={group} columns={[2, 3, 4, 6]} spacing={4}>
                {groupedCoaches[group]?.map((coach) =>
                  coach.first_name === 'Arena' ? null : (
                    <Link
                      key={coach.slug}
                      href={getHref(coach)}
                      passHref
                      legacyBehavior
                    >
                      <VStack
                        as="a"
                        spacing="1"
                        onClick={() => setCoachCache(coach)}
                      >
                        <PersonIcon person={coach} size="xl" />
                        <Text textAlign="center" textStyle={'copy_small'}>
                          {formatCoachName(coach)}
                        </Text>
                        <Text textAlign="center" textStyle={'copy_small'}>
                          {coach.list_title}
                        </Text>
                      </VStack>
                    </Link>
                  ),
                )}
              </SimpleGrid>
            </Box>
          ))}
      </VStack>
    </chakra.section>
  );
}
