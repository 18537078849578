import {
  Box,
  chakra,
  Collapse,
  Flex,
  Grid,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';

import { PlayIcon } from '@arena-labs/strive2-ui';

import { UserHighRecharge } from './icons/user-high-recharge';
import { UserLowRecharge } from './icons/user-low-recharge';
import { UserNullRecharge } from './icons/user-null-recharge';

export function RechargeEstimateCard({
  selection,
}: {
  selection: string | undefined | null;
}) {
  const selectionIsAbove = selection?.includes('above');
  return (
    <Grid templateColumns={'auto 1fr'} gap={6} alignItems={'center'} w={'full'}>
      <Box p={2} rounded={'card'}>
        {selectionIsAbove ? (
          <UserHighRecharge width="64px" height="64px" />
        ) : (
          <UserLowRecharge width="64px" height="64px" />
        )}
      </Box>
      <VStack spacing={2} align={'flex-start'}>
        <Text textStyle={'copy_bold'} mr={'auto'}>
          Inner State
        </Text>
        <Text textStyle={'copy_bold'} mr={'auto'} color={'neutral.800'}>
          Your reflection is {selectionIsAbove ? 'above' : 'below'} baseline.
        </Text>
      </VStack>
    </Grid>
  );
}

export function RechargeStatusCard({
  selection,
  hasHRV,
}: {
  selection: string | undefined | null;
  hasHRV: boolean;
}) {
  const selectionIsAbove = selection?.includes('above');
  return (
    <Grid templateColumns={'auto 1fr'} gap={6} alignItems={'center'} w={'full'}>
      <Box
        w={'100px'}
        h={'100px'}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {!selection ? (
          <UserNullRecharge width="100px" height="100px" />
        ) : selectionIsAbove ? (
          <UserHighRecharge width="100px" height="100px" />
        ) : (
          <UserLowRecharge width="100px" height="100px" />
        )}
      </Box>
      <VStack spacing={2} align={'flex-start'}>
        <Text textStyle={'h3'} mr={'auto'}>
          {hasHRV ? 'HRV Suggests' : 'No data from last night.'}
        </Text>
        <Text textStyle={'copy_bold'} mr={'auto'} color={'neutral.black'}>
          {!hasHRV
            ? `Remember to wear your ring while you sleep.`
            : `Your data is ${
                selectionIsAbove ? 'above' : 'below'
              } 8 day average.`}
        </Text>
      </VStack>
    </Grid>
  );
}

export function InsightsRechargeCard({
  isDataCard,
  selection,
  expanded,
  hasHRV,
}: {
  selection: string | undefined | null;
  isDataCard: boolean;
  expanded: boolean;
  hasHRV?: boolean;
}) {
  const selectionIsAbove = selection?.includes('above');
  return (
    <Box
      position={'relative'}
      rounded={'md'}
      w={'full'}
      bg={'neutral.black'}
      px={4}
      py={2}
      color={'neutral.white'}
    >
      <Flex w={'full'} justify={'space-between'}>
        <Text
          textStyle={'copy_extra_small'}
          as={Flex}
          mt={6}
          flexDir={'column'}
        >
          {isDataCard ? (
            'HRV'
          ) : (
            <>
              <chakra.span mt={-2}>Inner</chakra.span>
              <chakra.span>State</chakra.span>
            </>
          )}
        </Text>

        {!selection ? (
          <UserNullRecharge width="64px" height="64px" />
        ) : selection?.includes('above') ? (
          <UserHighRecharge width="64px" height="64px" />
        ) : (
          <UserLowRecharge width="64px" height="64px" />
        )}
      </Flex>
      <Collapse in={expanded} color={'neutral.500'}>
        {hasHRV === false ? (
          <Text textStyle={'copy_small'} textAlign={'left'}>
            No data from last night.
          </Text>
        ) : (
          <Text textStyle={'copy_small'} textAlign={'left'}>
            Your {isDataCard ? 'data' : 'reflection'} is{' '}
            {!selection
              ? 'not available'
              : selectionIsAbove
              ? 'above'
              : 'below'}{' '}
            {!selection ? null : isDataCard ? '14 day avg' : 'baseline'}.
          </Text>
        )}
      </Collapse>
    </Box>
  );
}

export function AdditionalInsightCards({
  metric,
  value,
  context,
  trend,
  metricIndicator,
  contextIndicator,
}: {
  trend: string | null;
  metric: string;
  value: string;
  context: string;
  metricIndicator?: boolean;
  contextIndicator?: boolean;
}) {
  const trendIncreasing = trend?.includes('above');
  return (
    <Flex
      rounded={'md'}
      direction={'column'}
      bg={'neutral.50'}
      color={'neutral.800'}
      w={'full'}
      py={4}
      px={3}
      gap={2}
      alignItems={'flex-start'}
    >
      <Text textStyle={'copy_bold'}>{metric}</Text>
      <Grid templateColumns={'auto auto'} alignItems={'center'}>
        <Text
          textStyle={'h3'}
          color={
            !trend
              ? 'neutral.300'
              : trendIncreasing
              ? 'positive.300'
              : 'negative.300'
          }
        >
          {value}
        </Text>
        {metricIndicator && (
          <Icon
            as={PlayIcon}
            color={trendIncreasing ? 'positive.300' : 'negative.300'}
            transform={`rotate(${trendIncreasing ? '-90' : '90'}deg)`}
            w={3}
            h={2}
            mx={1}
          />
        )}
      </Grid>

      <Grid templateColumns={'auto 1fr'} alignItems={'center'}>
        {contextIndicator && (
          <Icon
            as={PlayIcon}
            color={trendIncreasing ? 'positive.100' : 'negative.100'}
            transform={`rotate(${trendIncreasing ? '-90' : '90'}deg)`}
            w={3}
            h={2}
            mx={1}
          />
        )}
        <Text
          textStyle={'copy'}
          flexWrap={'nowrap'}
          fontSize={'12px'}
          color={'neutral.600'}
        >
          {context}
        </Text>
      </Grid>
    </Flex>
  );
}
