import 'swiper/css';
import 'swiper/css/effect-fade';

import { useState } from 'react';
import { Flex, Grid, Icon, Text } from '@chakra-ui/react';
import { useReducedMotion } from 'framer-motion';
import { EffectFade, Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { AnalyticsContext } from '@arena-labs/analytics';
import {
  ChevronLeftIcon,
  useGradient,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import {
  NotificationFearOfMissingOut,
  NotificationSuccessMetrics,
} from '@strive/notifications';
import {
  createDataStore,
  useDataStore,
  useUrlState,
  wait,
} from '@strive/utils';

import { EnableNotificationAction } from '../components/notifications/enable-notification-action';

export type EnableNotificationsProps = {
  onCompleted: () => void;
};

const ctaVariants = [
  'success_metrics',
  'fear_of_missing_out',
  'metrics+fomo',
] as const;

const ctaVariantStore = createDataStore({
  key: 'AB.LaunchpadNotifications',
  schema: z.enum(ctaVariants),
  fallback: ctaVariants[Math.floor(Math.random() * ctaVariants.length)],
  persistFallback: true,
  staleTime: 15 * 60 * 1000,
});

export function EnableNotifications({ onCompleted }: EnableNotificationsProps) {
  const [ctaVariant, , { query }] = useDataStore(ctaVariantStore);

  const bgGradient = useGradient('black_white');

  const [loadingState, setLoadingState] = useState<'loading' | 'ready'>(
    'loading',
  );
  const handleAnimationComplete = () =>
    wait(2_000).then(() => setLoadingState('ready'));

  return (
    <Flex
      h="full"
      direction="column"
      pb="calc(env(safe-area-inset-bottom) + 1rem)"
      bg={bgGradient}
    >
      <Grid
        px="6"
        py="3"
        w="full"
        templateColumns={'40px 1fr 40px'}
        alignItems={'center'}
        textAlign={'center'}
        color={'logo'}
      >
        <Flex as={'button'} gridColumn={1} onClick={() => history.back()}>
          <Icon as={ChevronLeftIcon} />
        </Flex>
        <Text textStyle={'h3'} gridColumn={2} aria-colspan={1}>
          Welcome to Strive
        </Text>
      </Grid>

      <WaitForQuery query={query}>
        {(variant) => (
          <AnalyticsContext context={{ notification_cta: ctaVariant }}>
            {match(variant)
              .with('fear_of_missing_out', () => (
                <EnableNotificationAction
                  onCompleted={onCompleted}
                  state={loadingState}
                >
                  <NotificationFearOfMissingOut
                    onAnimationComplete={handleAnimationComplete}
                  />
                </EnableNotificationAction>
              ))
              .with('success_metrics', () => (
                <EnableNotificationAction
                  onCompleted={onCompleted}
                  state={loadingState}
                >
                  <NotificationSuccessMetrics
                    onAnimationComplete={handleAnimationComplete}
                  />
                </EnableNotificationAction>
              ))
              .with('metrics+fomo', () => (
                <NotificationComboPage onCompleted={onCompleted} />
              ))
              .exhaustive()}
          </AnalyticsContext>
        )}
      </WaitForQuery>
    </Flex>
  );
}

type NotificationComboPageProps = {
  onCompleted: () => void;
};

function NotificationComboPage({ onCompleted }: NotificationComboPageProps) {
  const prefersReducedMotion = useReducedMotion();

  const [swiper, setSwiper] = useState<SwiperClass>();

  // Keep track of the current slide in the URL, so we can use back button to go back
  const slide = useUrlState({
    key: 'slide',
    schema: z.number(),
    fallback: 1,
    onChange: (value) => swiper?.slideTo(value - 1),
  });

  // Keep track of how many slides we've animated
  const [completed, setCompleted] = useState(0);
  const onSlideCompleted = async () => {
    await wait(2_000);
    setCompleted((prev) => {
      const next = prev + 1;
      if (next < (swiper?.slides.length ?? 0)) {
        slide.push(next + 1); // auto-transition to next slide
      }
      return next;
    });
  };

  return (
    <EnableNotificationAction
      onCompleted={onCompleted}
      state={completed === 2 ? 'ready' : 'loading'}
      secondaryButtonText={
        slide.value === 1 ? 'When will you message?' : undefined
      }
      onSecondaryButtonClick={
        slide.value === 1 ? () => slide.push(2) : undefined
      }
    >
      <Swiper
        style={{ width: '100%', height: '100%' }}
        allowTouchMove={false}
        history={{
          enabled: true,
          key: 'slide',
          keepQuery: true,
        }}
        effect={prefersReducedMotion ? 'fade' : 'slide'}
        modules={prefersReducedMotion ? [EffectFade] : undefined}
        onSwiper={setSwiper}
      >
        <SwiperSlide>
          <NotificationFearOfMissingOut
            onAnimationComplete={onSlideCompleted}
          />
        </SwiperSlide>
        <SwiperSlide>
          {completed >= 1 ? ( // delay animation until the previous slide is completed
            <NotificationSuccessMetrics
              onAnimationComplete={onSlideCompleted}
            />
          ) : null}
        </SwiperSlide>
      </Swiper>
    </EnableNotificationAction>
  );
}
