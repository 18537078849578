export type HexColor = `#${string}`;

export function addAlpha(color: HexColor, alpha: number) {
  if (color.length === 9) {
    // Strip off any existing alpha value
    color = color.slice(0, 7) as HexColor;
  }
  return (color +
    Math.round(alpha * 255)
      .toString(16)
      .toUpperCase()
      .padStart(2, '0')) as HexColor;
}

export function parseAlpha(color: HexColor) {
  if (color.length === 9) {
    const alpha = color.slice(7, 9);
    color = color.slice(0, 7) as HexColor;
    return [color, parseInt(alpha, 16) / 255] as const;
  }
  return [color, 1] as const;
}

const primary = {
  50: '#E5F2FF',
  100: '#CCE5FF',
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#0080FF',
  600: '#0066CC',
  700: '#004C99',
  800: '#003366',
  900: '#001933',
} as const;

const secondary = {
  50: '#FFFBE5',
  100: '#FFF7CC',
  200: '#FFF099',
  300: '#FFEB66',
  400: '#FFE033',
  500: '#FFD000',
  600: '#CCAD00',
  700: '#998200',
  800: '#665700',
  900: '#332B00',
} as const;

const neutral = {
  white: '#FFFFFF', //neutral.white equivalent
  50: '#F6F6F6',
  100: '#EFEFEF',
  200: '#E6E6E6',
  300: '#E0E0E0',
  400: '#C3C3C3',
  500: '#A8A8A8',
  600: '#656565',
  700: '#3E3E3E',
  800: '#333333',
  900: '#121212',
  black: '#1D1D1D', //neutral.black equivalent
} as const;

const white = neutral.white;
const black = neutral.black;

const neutralAlpha = {
  50: addAlpha(black, 0.05),
  100: addAlpha(black, 0.1),
  200: addAlpha(black, 0.2),
  300: addAlpha(black, 0.3),
  400: addAlpha(black, 0.4),
  500: addAlpha(black, 0.5),
  600: addAlpha(black, 0.6),
  700: addAlpha(black, 0.7),
  800: addAlpha(black, 0.8),
  900: addAlpha(black, 0.9),
};

const ACI = {
  belowBaseline: '#FF5200', // Same as ACIorange
  aboveBaseline: '#89F0FD', // Same as ACIBlue
} as const;

//keep 'positive', man
const positive = {
  50: '#E7FDF8',
  100: '#D3FDF2',
  200: '#73E5A7',
  300: '#43CC7F',
  400: '#007B62',
} as const;

const warning = {
  50: '#FFEFDA',
  100: '#FBEAD8',
  200: '#FFD7A3',
  300: '#FFB573',
  400: '#A65C00',
} as const;

const negative = {
  50: '#FEE9E9',
  100: '#FBC6C6',
  200: '#F39494',
  300: '#ED7272',
  400: '#D43D3D',
} as const;

const information = {
  50: '#EDF4FD',
  100: '#DAEAFB',
  200: '#A2CCE5',
  300: '#296DBD',
  400: '#234D7A',
} as const;

const functional = {
  favorite: '#FF3943',
};

const bg = {
  primary: `linear-gradient(90deg, #DDD6E026 0%, #D8E3E8 100%)`,
} as const;

const logo = primary['800'];

const icon = {
  primary: logo,
  tertiary: warning[400],
  neutral: neutral['200'],
};

export const elevation = {
  '0dp': '#1d1d1d',
  '1dp': '#282828',
  '2dp': '#2d2d2d',
  '3dp': '#2f2f2f',
  '4dp': '#313131',
  '6dp': '#363636',
  '8dp': '#383838',
  '12dp': '#3d3d3d',
  '16dp': '#3f3f3f',
  '24dp': '#414141',
};

export const colorsNew = {
  functional,
  information,
  logo,
  black,
  white,
  primary,
  secondary,
  neutral,
  elevation,
  positive,
  warning,
  negative,
  neutralAlpha,
  bg,
  icon,
  ACI,
};
