import { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { useFadeEntryFadeExit } from '@arena-labs/strive2-ui';

import { AciContext } from '../aci-context';
import { RechargeEstimateCard, RechargeStatusCard } from '../recharge-cards';

export function ComparisonAnimation({
  estimate,
  onAnimationEnd,
}: {
  estimate: string | undefined | null;
  onAnimationEnd: () => void;
}) {
  const { scope, animate } = useFadeEntryFadeExit();

  const aciResponse = AciContext.useSelector(
    (state) => state.context.aciResponse,
  );

  useEffect(() => {
    animate('#actualData', { opacity: 1 }, { duration: 0.5, delay: 1.5 }).then(
      () => setTimeout(onAnimationEnd, 3000),
    );
  }, [animate, onAnimationEnd]);

  return (
    <Box ref={scope} h={'full'}>
      <Flex id={'body'} h={'full'} direction={'column'} gap={4}>
        <Text textStyle={'h1'}>Last 24 hours</Text>
        <RechargeEstimateCard selection={estimate} />
        <Box id={'actualData'} opacity={0}>
          <RechargeStatusCard
            hasHRV={Boolean(aciResponse?.daily?.latest_hrv)}
            selection={aciResponse?.daily?.hrv_trend}
          />
        </Box>
      </Flex>
    </Box>
  );
}
