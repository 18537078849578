import { Fragment } from 'react';
import { Box, Divider, Flex, Grid, Text } from '@chakra-ui/react';
import { format } from 'date-fns';

import {
  BarGraphIcon,
  CalendarIcon,
  GoalIcon,
  Icon,
} from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

// import { ProgressLandingCompetitionCard } from '../../../../components/progress/landing/progress-landing-competition-card';
import { CompetitionStar } from '../competition-star';

export function CompetitionBackground() {
  return (
    <Flex direction="column" h="full" gap={10} pb={4}>
      <Flex
        py={2}
        px={5}
        position={'relative'}
        direction={'column'}
        justifyContent="space-evenly"
        mt="2"
      >
        {/* <ProgressLandingCompetitionCard
          name={'AnonymizedUser'}
          rank={13}
          score={89}
        /> */}
        <Box mx="auto">
          <CompetitionStar positionValue={'?'} size={'info'} />
        </Box>
      </Flex>
      <Box>
        <Divider px={6} ml={-6} borderColor={'neutral.800'} />
      </Box>

      <GameAndRulesExpectations />
    </Flex>
  );
}

export function GameAndRulesExpectations() {
  const { data: incentive } = $API.useGetIncentives();

  let compStartDate = null;
  let compEndDate = null;

  if (incentive?.[0]?.type === 'competition') {
    compStartDate = incentive[0].start_date;
    compEndDate = incentive[0].end_date;
  }

  const informationObjects = [
    {
      smallText: 'The path to victory',
      largeText:
        'Be consistent in your practicing your tools and keeping your HRV above your baseline!',
      Icon: GoalIcon,
    },
    {
      smallText: 'Timeline',
      largeText:
        compStartDate && compEndDate
          ? `${format(compStartDate, 'MMM d')} - ${format(
              compEndDate,
              'MMM d',
            )}`
          : 'Coach Will Communicate Competition Length!',
      Icon: CalendarIcon,
    },
    {
      smallText: 'How to win',
      largeText: 'Earn a Top 5 Ranking on the Strive Leaderboard.',
      Icon: BarGraphIcon,
    },
  ];

  return (
    <Flex direction="column" rowGap={4} justifyContent="space-evenly" mt="2">
      <Text textStyle={'copy_bold'}>
        What you can expect on the Leaderboard
      </Text>
      <Grid
        templateColumns={'auto 1fr'}
        columnGap={3}
        rowGap={4}
        alignItems={'center'}
      >
        {informationObjects.map((infoObject) => (
          <Fragment key={infoObject.largeText}>
            <Icon as={infoObject.Icon} boxSize={'6'} color={'primary.600'} />
            <Flex direction={'column'} gap={1}>
              <Text textStyle={'copy_extra_small'} color="neutral.700">
                {infoObject.smallText}
              </Text>
              <Text textStyle={'copy_small'} lineHeight={'tall'}>
                {infoObject.largeText}
              </Text>
            </Flex>
          </Fragment>
        ))}
      </Grid>
    </Flex>
  );
}
