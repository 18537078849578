import { Text, TextProps } from '@chakra-ui/react';

import { textStyles } from '@strive/theme/tokens';

export const TEXT = {
  H1: typography('h1', { as: 'h1' }),
  H2: typography('h2', { as: 'h2' }),
  H3: typography('h3', { as: 'h3' }),
  H4: typography('h4', { as: 'h4' }),
  H5: typography('h5', { as: 'h5' }),
  COPY: typography('copy'),
  COPY_BOLD: typography('copy_bold'),
  COPY_SMALL: typography('copy_small'),
  COPY_SMALL_THIN: typography('copy_small_thin'),
  COPY_EXTRA_SMALL: typography('copy_extra_small'),
  BUTTON: typography('button'),
  CAPTIONS: typography('captions'),
};

/**
 * Creates a text component with the given style from the theme
 */
function typography(textStyle: keyof typeof textStyles, textProps?: TextProps) {
  const Component = function (props: TextProps) {
    return <Text {...textStyles[textStyle]} {...textProps} {...props} />;
  };
  Component.displayName = `text.${textStyle}`;
  return Component;
}
