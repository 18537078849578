import { useState } from 'react';
import { useMount } from 'react-use';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

import { $API } from '@strive/api';
import { haptics } from '@strive/device';

import { incentiveImages } from '../incentives/rewards/reward-config';
import { useIncentiveHub } from '../incentives/use-incentive-hub';
import { AppGateProps } from './app-gate-listener';
import { CongratsGate } from './congrats-gate';

export default function RewardGate({
  appGate,
  updateGates,
}: AppGateProps<'reward_gate'>) {
  const [previewActive, setPreviewActive] = useState(
    appGate.slug === 'extra_bonus' ? true : false,
  );
  const incentiveHub = useIncentiveHub();
  const { refetch } = $API.useGetIncentives();

  useMount(() => haptics.notification('SUCCESS'));

  const clearGate = () => {
    updateGates({
      type: 'reward_gate',
      slug: appGate.slug,
    }).then(() => {
      refetch().then(() => {
        incentiveHub.onOpen();
      });
    });
  };

  const PreView = () => {
    return (
      <VStack textAlign={'center'} pb="10" pt={'300px'}>
        <Heading px="6">Extra Bonus!</Heading>
        <Text textStyle={'copy_bold'}>
          A gift only for the top 3 finishers of the program!
        </Text>
      </VStack>
    );
  };

  return appGate.slug === 'congrats' ? (
    <CongratsGate appGate={appGate} updateGates={updateGates} />
  ) : (
    <Drawer isOpen placement="bottom" onClose={clearGate}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius="25px" background={'#1E5563'}>
        <DrawerBody>
          {previewActive ? (
            <PreView />
          ) : (
            <VStack textAlign={'center'} pb="20">
              {appGate.properties?.image && (
                <Image
                  py="20px"
                  src={incentiveImages[appGate.properties?.image]}
                  alt={'image of a water bottle'}
                  w={'150px'}
                />
              )}
              <Text textStyle={'h5'} px="6">
                You&apos;ve Earned {appGate.properties?.reward}!
              </Text>
              <Text textStyle={'copy_bold'}>{appGate.properties?.trigger}</Text>
            </VStack>
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button
            w="full"
            variant={'primary'}
            onClick={() => {
              if (previewActive) {
                setPreviewActive(false);
              } else {
                clearGate();
              }
            }}
          >
            {previewActive ? 'Open Gift' : 'Add to Treasure Box'}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
