/**
 * -apple-system — San Francisco in Safari (on Mac OS X and iOS); Neue Helvetica and Lucida Grande on older versions of Mac OS X.
 * system-ui — default UI font on a given platform.
 * BlinkMacSystemFont — equivalent of -apple-system, for Chrome on Mac OS X.
 * Roboto — Android (Ice Cream Sandwich (4.0)+) and Chrome OS.
 * Helvetica Neue - Pretty much universally available
 * sans-serif - Fallback - lets the OS provide a sans-serif font
 */
export const baseFont = [
  'Inter-Regular',
  '-apple-system',
  'system-ui',
  'BlinkMacSystemFont',
  'Roboto',
  'Helvetica Neue',
  'sans-serif',
];

const systemFont = baseFont.join(', ');

export const fonts = {
  heading: systemFont,
  body: systemFont,
  secondary: 'RacingSansOne-Regular',
};

export const letterSpacings = {
  normal: '0',
  wide: '0.004em',
  wider: '0.025em',
};

export const lineHeights = {
  tallest: 1.7,
  tall: 1.6,
  midTall: 1.5,
  middle: 1.4,
  short: 1.3,
  shortest: 1.25,
};

export const textStyles = {
  h1: {
    fontSize: `2rem`,
    fontWeight: 700,
    lineHeight: 'shortest',
    letterSpacing: 'normal',
  },
  h2: {
    fontSize: `1.75rem`,
    fontWeight: 600,
    lineHeight: 'middle',
    letterSpacing: 'wide',
  },
  h3: {
    fontSize: `1.5rem`,
    fontWeight: '600',
    lineHeight: 'short',
    letterSpacing: 'normal',
  },
  h4: {
    fontSize: `1.25rem`,
    fontWeight: '600',
    lineHeight: 'tall',
    letterSpacing: 'normal',
  },
  h5: {
    fontSize: `1.125rem`,
    fontWeight: '600',
    lineHeight: 'short',
    letterSpacing: 'normal',
  },
  copy: {
    fontSize: `1rem`,
    fontWeight: '500',
    lineHeight: 'midTall',
    letterSpacing: 'normal',
  },
  copy_bold: {
    fontSize: `1rem`,
    fontWeight: '600',
    lineHeight: 'midTall',
    letterSpacing: 'normal',
  },
  copy_small: {
    fontSize: `0.75rem`,
    fontWeight: '500',
    lineHeight: 'short',
    letterSpacing: 'wide',
  },
  copy_small_thin: {
    fontFamily: 'Inter-Light',
    fontSize: `0.75rem`,
    fontWeight: '400',
    lineHeight: 'short',
    letterSpacing: 'wide',
  },
  copy_extra_small: {
    fontSize: `0.625rem`,
    fontWeight: '500',
    lineHeight: 'tall',
    letterSpacing: 'wide',
  },
  captions: {
    fontSize: `0.75rem`,
    fontWeight: '600',
    lineHeight: 'short',
    letterSpacing: 'wider',
  },

  button: {
    fontSize: `0.875rem`,
    fontWeight: '500',
    lineHeight: 'tallest',
    letterSpacing: 'wide',
  },
};

//consider adding back old textstyle objects and making them stand out in a meaningful way, this can help ID issues with old styles lurking around
export const fontSizes = {
  h1: textStyles.h1.fontSize,
  h2: textStyles.h2.fontSize,
  h3: textStyles.h3.fontSize,
  h4: textStyles.h4.fontSize,
  h5: textStyles.h5.fontSize,
  copy: textStyles.copy.fontSize,
  copy_bold: textStyles.copy_bold.fontSize,
  copy_small: textStyles.copy_small.fontSize,
  copy_small_thin: textStyles.copy_small_thin.fontSize,
  // copy_extra_small_thin: textStyles.copy_extra_small_thin.fontSize,
  button: textStyles.button.fontSize,
  captions: textStyles.captions.fontSize,
};

export const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};
