import { useEffect, useState } from 'react';
import {
  Button,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import { produce } from 'immer';

import { VideoFilters, VideoQuery } from '@arena-labs/shared-models';
import {
  ChevronLeftIcon,
  NavigationDrawer,
  SwipeableDrawerBody,
} from '@arena-labs/strive2-ui';

import { FilterSectionCoaches } from './filter-section-coaches';
import { FilterSectionDurations } from './filter-section-durations';
import { FilterSectionSkills } from './filter-section-skills';

export interface FiltersProps {
  filters: VideoQuery;
  isOpen: boolean;
  onClose: () => void;
  onApply: (filters: VideoFilters) => void;
}

// Given a Model (type) that looks like { foo: FooType, bar: BarType, ... }
// describe a function that takes a key of that model, and returns a function
// which accepts a value of the type for that given key
// e.g.
// ```
// declare function handleUpdate: CurriedReactHandler<{ foo: FooType, bar: BarType }>
// handleUpdate('foo') // returns: (value: FooType) => void
// handleUpdate('bar') // returns: (value: BarType) => void
// ```
// See https://bit.ly/395jRbb
type CurriedReactHandler<Model> = <T extends keyof Model>(
  type: T,
) => (values: Model[T]) => void;

export function Filters({
  filters: initialFilters,
  isOpen,
  onClose,
  onApply,
}: FiltersProps) {
  const [filters, setFilters] = useState(initialFilters);
  useEffect(() => {
    if (isOpen) {
      setFilters(initialFilters);
    }
  }, [isOpen, initialFilters]);

  const handleFilterChange: CurriedReactHandler<VideoQuery> =
    (type) => (value) => {
      setFilters(
        produce(filters, (draft) => {
          draft[type] = value;
        }),
      );
    };

  const handleClose = () => onClose();

  return (
    <NavigationDrawer
      placement="top"
      onClose={handleClose}
      size="full"
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Button
              variant="primary"
              onClick={handleClose}
              backgroundColor="transparent"
              p={4}
              ml={-4}
            >
              <Icon mr="1" as={ChevronLeftIcon} />
              <Text fontFamily="brand" textStyle={'copy_bold'}>
                FILTER
              </Text>
              <VisuallyHidden>
                Close dialog without applying filters
              </VisuallyHidden>
            </Button>
            <Button
              size="sm"
              textDecoration="underline"
              textUnderlineOffset="0.25em"
              borderRadius="sm"
              colorScheme="strive.secondary"
              mr="-2"
              onClick={() => setFilters(new VideoQuery())}
            >
              CLEAR ALL
            </Button>
          </Flex>
        </DrawerHeader>
        <SwipeableDrawerBody
          direction="Up"
          onSwipe={() => handleClose()}
          delta={250}
        >
          <VStack spacing={5}>
            <FilterSectionDurations
              selected={filters.duration}
              onChange={handleFilterChange('duration')}
            />
            <FilterSectionSkills
              selected={filters.skills}
              onChange={handleFilterChange('skills')}
            />
            <FilterSectionCoaches
              selected={filters.coaches}
              onChange={handleFilterChange('coaches')}
            />
            <Button
              width="100%"
              variant="primary"
              onClick={() => onApply(filters)}
            >
              APPLY FILTERS
            </Button>
          </VStack>
        </SwipeableDrawerBody>
      </DrawerContent>
    </NavigationDrawer>
  );
}
