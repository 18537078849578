import React from 'react';
import { Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { AnalyticsContext, DataTrackingTag } from '@arena-labs/analytics';
import { PillarPractice } from '@arena-labs/shared-models';
import { ZStack } from '@arena-labs/strive2-ui';

import { useAciMachine } from './aci-context';
import { CheckInInsights } from './checkin-insights/check-in-insights';
import { ComparisonAnimation } from './checkin-insights/comparison-animation';
import { GeneratingInsights } from './checkin-insights/generating-insights';
import { ContextInfo } from './context-info';
import { AciUserInputs } from './user-input-views/aci-user-inputs';

export function ACI({
  banner,
  logPracticeCard,
}: {
  banner: (onNavigateBack?: () => void) => React.ReactNode;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
}) {
  const [state, send] = useAciMachine();

  const shouldShowBanner =
    state.matches({ Open: 'userInputs' }) ||
    state.matches({ Open: 'insights' }) ||
    state.matches({ Open: 'contextInfo' });

  const canNavigateBack = state.can({ type: 'back' });
  const goBack = () => send({ type: 'back' });

  return (
    <AnalyticsContext context={{ tags: [DataTrackingTag.ACI] }}>
      {/* i want to move the banner up to this level, but i think we might want to combine the state machines in that case TODO */}
      {shouldShowBanner && banner(canNavigateBack ? goBack : undefined)}

      <Flex direction={'column'} w={'full'} pt={0} px={6} gridArea="content">
        <ZStack h={'full'}>
          <AnalyticsContext context={{ tags: [DataTrackingTag.ACIForm] }}>
            <AnimatePresence>
              {state.matches({ Open: 'userInputs' }) ? (
                <AciUserInputs key="inputs" logPracticeCard={logPracticeCard} />
              ) : null}
            </AnimatePresence>

            <AnimatePresence>
              {state.matches({ Open: 'generatingInsights' }) ? (
                <GeneratingInsights />
              ) : null}
            </AnimatePresence>

            <AnimatePresence>
              {state.matches({ Open: 'comparison' }) ? (
                <ComparisonAnimation
                  onAnimationEnd={() => send({ type: 'ANIMATION_END' })}
                  estimate={state.context.aciResponse?.reflection_selection}
                />
              ) : null}
            </AnimatePresence>
          </AnalyticsContext>

          <AnalyticsContext context={{ tags: [DataTrackingTag.ACIInsights] }}>
            <AnimatePresence>
              {state.matches({ Open: 'insights' }) ? (
                <CheckInInsights
                  logPracticeCard={logPracticeCard}
                  onOpenInfo={() => send({ type: 'OPEN_CONTEXT_INFO' })}
                />
              ) : null}
            </AnimatePresence>

            <AnimatePresence>
              {state.matches({ Open: 'contextInfo' }) ? <ContextInfo /> : null}
            </AnimatePresence>
          </AnalyticsContext>
        </ZStack>
      </Flex>
    </AnalyticsContext>
  );
}
