import { Box, Flex, Grid, Icon, Text } from '@chakra-ui/react';
import { useMachine } from '@xstate/react';

import {
  AuthResponse,
  isAuthToken,
  SignupAuthResponse,
} from '@arena-labs/shared-models';
import { AlertBox, ChevronLeftIcon } from '@arena-labs/strive2-ui';
import { assert, useXstateDoneEffect } from '@strive/utils';

import EmailFound from '../components/email-verification/email-found';
import EmailVerification from '../components/email-verification/email-verification';
import { verifyEmailMachine } from '../lib/find-and-verify-email.machine';

type MatchProfileProps = {
  token: SignupAuthResponse;
  openStriveSupport: () => void;
  onComplete: (tokens: AuthResponse) => void;
};

export function MatchProfile({
  token,
  openStriveSupport,
  onComplete,
}: MatchProfileProps) {
  const [state, send, service] = useMachine(verifyEmailMachine, {
    devTools: true,
    context: {
      authTokens: token,
    },
  });

  useXstateDoneEffect(service, (context, event) => {
    assert(
      isAuthToken(context.authTokens),
      'Profile match should complete with valid tokens',
    );
    onComplete(context.authTokens);
  });

  const error = state.context.error;
  return (
    <Flex
      h="full"
      direction="column"
      pb="calc(env(safe-area-inset-bottom) + 1rem)"
    >
      <Grid
        px="6"
        py="3"
        w="full"
        templateColumns={'40px 1fr 40px'}
        alignItems={'center'}
        textAlign={'center'}
        color={'logo'}
      >
        {state.matches('Confirmation') ? (
          <Flex
            as={'button'}
            gridColumn={1}
            onClick={() => {
              send('Start again');
            }}
          >
            <Icon as={ChevronLeftIcon} />
          </Flex>
        ) : null}
        <Text textStyle={'h3'} gridColumn={2} aria-colspan={1}>
          Account Verification
        </Text>
      </Grid>

      {Boolean(error) && (
        <Box w="full" px={6} pt={6}>
          <AlertBox
            showIcon
            status="error"
            title="An Error Occurred"
            description={
              state.matches('Form.Error')
                ? `The Email could not be found or has been previously claimed. Be sure you have entered the email correctly. Please Try Again!`
                : 'There was an error claiming the account, please try again!'
            }
          />
        </Box>
      )}
      {/* ENTER EMAIL TO VERIFY */}
      {state.matches('Form') ? (
        <EmailVerification
          openSupport={() => {
            openStriveSupport();
          }}
          onSubmit={(email: string) => send({ type: 'Find Profile', email })}
        />
      ) : null}

      {/* EMAIL AND ACCOUNT FOUND */}
      {state.matches('Confirmation') && state.context.profile ? (
        <EmailFound
          onClaimEmail={() => send('Confirm')}
          onDenyEmail={() => send('Start again')}
          foundProfile={state.context.profile}
        />
      ) : null}
    </Flex>
  );
}
