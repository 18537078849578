import { useMemo } from 'react';
import { Box, BoxProps, Grid, SystemStyleObject, Text } from '@chakra-ui/react';

import { NoBreakText, ZStack } from '@arena-labs/strive2-ui';

import { getRandomQuote } from './quotes';

const borderWidth = '22px';
const invertedV = 'polygon(0 0, 0 100%, 50% 0, 100% 100%, 100% 0)';
const cutOut = {
  aspectRatio: '4/1',
  shapeOutside: invertedV,
  clipPath: invertedV,
} satisfies SystemStyleObject;

export type ACIHeaderProps = {
  wrapper?: BoxProps;
};

export function ACIHeader({ wrapper }: ACIHeaderProps) {
  const quote = useMemo(getRandomQuote, []);

  return (
    <Grid position="sticky" top="0" pointerEvents="none" {...wrapper}>
      <Grid
        templateRows="auto minmax(56px, auto)"
        p="6"
        bg="bg.primary"
        width="100%"
        gap="2"
        pointerEvents="auto"
      >
        <Text textStyle={'h2'}>Arena Check-In</Text>
        {quote && (
          <Text textStyle={'copy_small'} as="blockquote" alignSelf="center">
            {quote.text}{' '}
            <NoBreakText display="inline-block" text={`- ${quote.source}`} />
          </Text>
        )}
      </Grid>
      <ZStack
        borderTop="1px solid bg.primary"
        mt="-1px"
        w="100%"
        sx={{ '*': { pointerEvents: 'auto' } }}
      >
        <Box h={borderWidth} bg="bg.secondary" />
        <Box
          bgColor="bg.secondary"
          transform={`translateY(${borderWidth})`}
          sx={cutOut}
        />
        <Box bgColor="bg.primary" sx={cutOut} />
      </ZStack>
    </Grid>
  );
}
