import { Box, Grid, Text } from '@chakra-ui/react';

import { Card, CardProps } from './card';

export type NumberedCardProps = {
  number: number;
} & CardProps;

export function NumberedCard({
  number,
  children,
  ...props
}: NumberedCardProps) {
  return (
    <Card {...props} p="0">
      <Grid
        templateColumns="42px 1fr"
        borderRadius="card"
        p="0"
        overflow="hidden"
        height="full"
      >
        <Box bg="var(--card-bg-dark)" display="grid" placeItems="center">
          <Text textStyle={'h1'} color="white" opacity={0.6}>
            {number}
          </Text>
        </Box>
        <Box>{children}</Box>
      </Grid>
    </Card>
  );
}
